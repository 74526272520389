import React, { useState, useEffect, useCallback, useRef } from 'react';
import { get, post, put, del } from '../utils/api'; // Import put function
import { useAuth } from '../contexts/AuthContext';
import { confirmAlert } from 'react-confirm-alert';
import { Button } from '@/components/shadcn/button';
import { useNavigate } from 'react-router-dom';
import HomePageV2 from './HomePageV2';
import QuizPage from '../views/Quiz/Quiz';
import { useDaily } from '@daily-co/daily-react';

import 'react-confirm-alert/src/react-confirm-alert.css';


function LearningSession({ isTeacherView, studentId, studentState, studentSessionid }) {


    const [currentQuizSubmission, setCurrentQuizSubmission] = useState(null);
    const [currentView, setCurrentView] = useState(null);

    const [sessionStarted, setSessionStarted] = useState(false);
    const [currentTopic, setCurrentTopic] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [quizJustFinished, setQuizJustFinished] = useState(false);
    const [lessonJustFinished, setLessonJustFinished] = useState(false);

    const [quizSubmissionId, setQuizSubmissionId] = useState(null);
    const [lessonId, setLessonId] = useState(null);
    const [topicId, setTopicId] = useState(null);

    const navigate = useNavigate();

    const { logout, loggedInChild } = useAuth();

    const [learningState, setLearningState] = useState(
        studentState ? studentState : null
       /* {
        currentView: null,
        currentStep: null,
        totalSteps: null,
        isQuizMode: false,
        currentQuestionIndex: null,
        totalQuestions: null,
        sessionStarted: false
    }*/);

    const callObject = useDaily();

    const initializationRef = useRef(false);

// Keep this separate as it handles a different concern
useEffect(() => {
    if (studentState) {
        setLearningState(studentState);
        //console.log('Learning State updated:', studentState);
    }
}, [studentState]);

    useEffect(() => {
        if (initializationRef.current) return;

        if (!loggedInChild && !isTeacherView) {
            confirmAlert({
                title: 'No Child Logged In',
                message: 'Please log in as a child to access the learning session.',
                buttons: [
                    {
                        label: 'Go to Children Dashboard',
                        onClick: () => navigate('/children-dashboard')
                    }
                ],
                closeOnEscape: false,
                closeOnClickOutside: false
            });
            return;
        }

        //console.log('the use Effect has just run')
        fetchCurrentTopic();
        
        initializationRef.current = true;
    }, [/*loggedInChild*/]);

    // Update learning state whenever relevant states change
    // I don't think we need the below useEffect as all state changes should be handled by the
    // handleLessonStateChange and handleQuizStateChange functions below which are called directly 
    // from the HomePageV2 and QuizPage components

   /* useEffect(() => {
        if (!isTeacherView) {
            const newState = {
                currentView,
               // currentStep: currentView === 'lesson' ? currentLessonStep : null,
                totalSteps: currentTopic?.steps?.length,
                isQuizMode: currentView === 'quiz',
                //currentQuestionIndex: currentView === 'quiz' ? currentQuestionIndex : null,
                //totalQuestions,
                //sessionStarted
            };
            
            setLearningState(newState);
            
            // Broadcast state to other participants
            if (callObject && sessionStarted) {
                callObject.sendAppMessage({
                    type: 'learning-state-update',
                    data: {
                        //userId: loggedInChild._id,
                        studentSessionid: studentSessionid,
                        state: newState
                    }
                });
            }
        }
    }, [
        currentView,
       // currentLessonStep,
        currentTopic,
       // currentQuestionIndex,
       // totalQuestions,
        sessionStarted,
       // isTeacherView,
        callObject,
        loggedInChild
    ]);
    */

      // Modified version of handleStartQuiz from HomePageV2
  const handleStartQuiz = async (lessonData, childTopicData, userData, loggedInChildData) => {
    try {
      // Set learning stage to "quiz"
      await put(`/childTopics/${loggedInChildData._id}/${childTopicData.topicId}/learningStage`, 
        { learningStage: 'quiz' }, 
        true
      );

      // Create quiz submission
      const response = await post('/quiz/quiz-submissions', {
        lessonId: lessonData._id,
        userId: userData._id,
        childId: loggedInChildData._id,
        topicId: childTopicData.topicId,
        QuizStatus: 'Pending'
      }, true);

      setQuizSubmissionId(response._id);
      setLessonId(lessonData._id);
      setTopicId(childTopicData.topicId);
      setSessionStarted(false);
      setLessonJustFinished(true);
      setCurrentView('quiz');
    } catch (error) {
      console.error('Error starting quiz:', error);
    }
  };


    const fetchCurrentTopic = async () => {
        try {

            // when child is progressing through lessons, do we need to filter to lessons for which lessonVisible = true?
            // First get current topic
            const currentTopicResponse = await get(`/children/${loggedInChild._id}/current-topic`, true);
            
            // Then get childTopic if we have a currentTopicId
            let childTopicResponse = null;
            if (currentTopicResponse.currentTopicId) {
                childTopicResponse = await get(
                    `/childTopics/${loggedInChild._id}/${currentTopicResponse.currentTopicId._id}`, 
                    true
                );
            }

            // If either is missing, we need to set up default topic
            if (!currentTopicResponse.currentTopicId || !childTopicResponse) {
                // Get the "View Lesson" topic
                const viewLessonTopic = await get('/topics/search?topic=View Lesson', true);
                
                if (!viewLessonTopic) {
                    throw new Error('View Lesson topic not found');
                }

                // Find appropriate lesson based on year group
                let firstLesson = null;
                for (const lessonObj of viewLessonTopic.lessons) {
                    //const lesson = await get(`/lessons/${lessonObj.lessonId._id}`, true);
                    if (lessonObj.lessonId.yearGroupLevel === loggedInChild.yearGroup) {
                        firstLesson = lessonObj.lessonId;
                        break;
                    }
                }

                // If no lesson matches year group, use first lesson
                if (!firstLesson && viewLessonTopic.lessons.length > 0) {
                    firstLesson = viewLessonTopic.lessons[0].lessonId;  /*await get(`/lessons/${viewLessonTopic.lessons[0].lessonId}`, true);*/
                }

                if (!firstLesson) {
                    throw new Error('No lessons found in View Lesson topic');
                }

                // Update child's currentTopicId if needed
                if (!currentTopicResponse.currentTopicId) {
                    await put(`/children/${loggedInChild._id}/current-topic`, {
                        topicId: viewLessonTopic._id
                    }, true);
                }


                // Create childTopic if needed
                if (!childTopicResponse) {
                    //console.log('Creating childTopic for View Lesson topic');
                    await post('/childTopics/start-learning', {
                        childId: loggedInChild._id,
                        topicId: viewLessonTopic._id,
                        lessonId: firstLesson._id
                    }, true);
                }

                // Fetch updated topic
                const updatedTopic = await get(`/children/${loggedInChild._id}/current-topic`, true);
                setCurrentTopic(updatedTopic.currentTopicId);
            } else {
                setCurrentTopic(currentTopicResponse.currentTopicId);
            }
            
            setLoading(false);
        } catch (err) {
            console.error('Error in fetchCurrentTopic:', err);
            setError('Failed to fetch current topic');
            setLoading(false);
        }
    };

    const handleContinue = async () => {

        // when child is progressing through lessons, do we need to filter to lessons for which lessonVisible = true?
        
        setLessonJustFinished(false);
        setQuizJustFinished(false);
        const response = await get(`/childTopics/${loggedInChild._id}/${currentTopic._id}`, true);
        const { currentLesson, learningStage } = response;
          
        if (learningStage === 'quiz') {
            const quizSubmissions = await get(`/quiz/quiz-submissions/search?childId=${loggedInChild._id}&topicId=${currentTopic._id}&lessonId=${currentLesson._id}&QuizStatus=Pending`, true);

            if (quizSubmissions && quizSubmissions.length > 0) {
                const quizSubmissionId = quizSubmissions[0]._id;
                setCurrentQuizSubmission(quizSubmissionId);
                setLessonId(currentLesson._id);
                setTopicId(currentTopic._id);
            } else {
                console.log('No pending quiz submission found, creating a new one');
                try {
                    // Create a new quiz submission
                    const response = await post('/quiz/quiz-submissions', {
                        lessonId: currentLesson._id,
                        childId: loggedInChild._id,
                        topicId: currentTopic._id,
                        QuizStatus: 'Pending'
                    }, true);
                    
                    setCurrentQuizSubmission(response._id);
                    setLessonId(currentLesson._id);
                    setTopicId(currentTopic._id);
                } catch (error) {
                    console.error('Error creating new quiz submission:', error);
                    // Handle error appropriately - maybe show an error message to the user
                }
            }

        }

        setCurrentView(learningStage);
        setSessionStarted(true);
    }

    const handleQuizCompleted = async () => {
        try {
            // First, get the full topic document to access the ordered lessons
            const topicResponse = await get(`/topics/${currentTopic._id}`);
            
            // Get the current childTopic to find current lesson
            const childTopicResponse = await get(`/childTopics/${loggedInChild._id}/${currentTopic._id}`, true);
            const currentLessonId = childTopicResponse.currentLesson._id;

            // Find current lesson's position in the ordered lessons array
            const currentLessonIndex = topicResponse.lessons.findIndex(
                lesson => lesson.lessonId._id === currentLessonId
            );

            // Check if there's a next lesson
            if (currentLessonIndex < topicResponse.lessons.length - 1) {
                // Get the next lesson
                const nextLesson = topicResponse.lessons[currentLessonIndex + 1].lessonId;

                // Update childTopic with next lesson
                await put(`/childTopics/${loggedInChild._id}/${currentTopic._id}`, {
                    currentLesson: nextLesson._id,
                    currentLessonStep: 0,
                    learningStage: 'lesson'
                }, true);

                setQuizJustFinished(true);
                setSessionStarted(false);
            } else {
                // This was the last lesson in the topic
                // Clear the current topic from the child
                await put(`/children/${loggedInChild._id}/current-topic`, { topicId: null }, true);
                
                // Delete the childTopic entry
                await del(`/childTopics/${loggedInChild._id}/${currentTopic._id}`, true);

                // Handle completion of entire topic
                setQuizJustFinished(true);
                setSessionStarted(false);
                // You might want to add additional state/UI handling for topic completion
            }
        } catch (error) {
            console.error('Error handling quiz completion:', error);
            // Handle error appropriately
        }
    };

    const handleLessonStateChange = useCallback((lessonState) => {
        if (!isTeacherView) {
            const newState = {
                latestLesson: lessonState.latestLesson,
                currentView: 'lesson',
                currentStep: lessonState.currentLessonStep,
                totalSteps: lessonState.totalSteps,
                isQuizMode: false,
                currentQuestionIndex: null,
                totalQuestions: null,
                //sessionStarted: lessonState.sessionStarted,
                //lessonTitle: lessonState.lessonTitle,
                //basiclessonText: lessonState.basiclessonText
            };

           // don't need this setLearningState, as handleLessonStateChange is used to pass state from
           // a student to the teacher, but learningState is used on the teacher's side once the student
           // has already passed the state to the teacher in the first instance
           // if we were to call setLearningState here, we would be calling it when we're still on the student's side,
           // before the student has passed the state to the teacher
           // setLearningState(newState);
            
            if (callObject && sessionStarted) {
                callObject.sendAppMessage({
                    type: 'learning-state-update',
                    data: {
                        //userId: loggedInChild._id,
                        studentSessionid: studentSessionid,
                        state: newState
                    }
                });
            }
        }
    }, [isTeacherView, callObject, sessionStarted, loggedInChild]);

    const handleQuizStateChange = useCallback((quizState) => {
        if (!isTeacherView) {
            const newState = {
                quizSubmission: quizState.quizSubmission,
                currentView: 'quiz',
                currentStep: null,
                totalSteps: null,
                isQuizMode: true,
                currentQuestionIndex: quizState.currentQuestionIndex,
                totalQuestions: quizState.totalQuestions,
                sessionStarted: quizState.quizStarted,
                quizStatus: quizState.quizStatus
            };

            // don't need this setLearningState, as handleLessonStateChange is used to pass state from
           // a student to the teacher, but learningState is used on the teacher's side once the student
           // has already passed the state to the teacher in the first instance
           // if we were to call setLearningState here, we would be calling it when we're still on the student's side,
           // before the student has passed the state to the teacher
            //setLearningState(newState);
            
            if (callObject && sessionStarted) {
                callObject.sendAppMessage({
                    type: 'learning-state-update',
                    data: {
                        //userId: loggedInChild._id,
                        studentSessionid: studentSessionid,
                        state: newState
                    }
                });
            }
        }
    }, [isTeacherView, callObject, sessionStarted, loggedInChild]);

    const handleStudentInput = useCallback((inputData) => {
        if (!isTeacherView && callObject) {
            callObject.sendAppMessage({
                type: 'student-input-update',
                data: {
                    studentSessionid: studentSessionid,
                    questionType: inputData.questionType,
                    input: inputData.value,
                    isSubmitted: inputData.isSubmitted,
                    isCorrect: inputData.isCorrect
                }
            });
        }
    }, [isTeacherView, callObject, studentSessionid]);

    // If no child is logged in, don't render the main content
    if (!loggedInChild) {
        return null; // The alert will show and redirect
    }

    return (
        <div /*className="container mx-auto p-4 min-h-screen flex flex-col"*/ >

            {!currentTopic && (
                <div className="h-screen flex items-center justify-center">
                    <p>PLEASE ASK YOUR TUTOR TO SET UP A LESSON FOR YOU</p>
                </div>
            )}
           {/* <div className="h-screen flex items-center justify-center"> */}

           
            {currentTopic && !sessionStarted && (
                <div className="h-screen flex flex-col items-center justify-center">
                    {quizJustFinished && (               
                        <p>
                            Well done on finishing the practice questions! Click the button to continue to the next topic!
                        </p>               
                    )}

                    {lessonJustFinished && (               
                        <p>
                            Well done on finishing the lesson! Click the button to continue to the practice questions!
                        </p>   
                    )}

                    <Button
                        onClick={handleContinue}
                        className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"                    
                    >
                        Continue Learning
                    </Button>
                </div>
            )}

            {currentTopic && sessionStarted && currentView === 'lesson' && (
                        <HomePageV2 
                        onQuizStart={handleStartQuiz}
                        preventNavigation={true}
                        {...(studentState && { studentInputState: learningState })}  // Only pass if studentState exists
                        onStateChange={handleLessonStateChange}
                        onInputChange={handleStudentInput}
                      />
          
            )}

            {currentTopic && sessionStarted && currentView === 'quiz' && (
                <QuizPage 
                quizSubmissionId={currentQuizSubmission}
                lessonId={lessonId}
                topicId={topicId}
                onQuizComplete={handleQuizCompleted}
                preventNavigation={true}
                onStateChange={handleQuizStateChange}
                onInputChange={handleStudentInput}
            />
            )}
        </div>
       // </div>
    );
}

export default LearningSession;