import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from '@/components/shadcn/card';
import { Button } from '@/components/shadcn/button';
import { PencilIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { post } from '../../../utils/api';
import { Alert, AlertDescription } from '@/components/shadcn/alert';
import { useAuth } from '../../../contexts/AuthContext';
import Cookies from 'js-cookie';
import { verifyToken } from '../../../services/authService';

function Review({ 
  subscriptionPlan, 
  childDetails, 
  paymentDetails,
  onEditSection
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const { user, login } = useAuth();

  //console.log('Review childDetails:', childDetails);
  //console.log('First child scheduledLessons:', childDetails?.[0]?.scheduledLessons);

  // Validation checks
  const getValidationErrors = () => {
    const errors = [];

    if (!subscriptionPlan) {
      errors.push({
        message: 'Please select a subscription plan',
        section: 'SUBSCRIPTION'
      });
    }

    if (!childDetails?.length) {
      errors.push({
        message: 'Please add child details and schedules',
        section: 'SCHEDULE'
      });
    } else {
      // Check if each child has scheduled lessons
      childDetails.forEach((child, index) => {
        if (!child.scheduledLessons?.length) {
          errors.push({
            message: `Please add lesson schedules for ${child.name}`,
            section: 'SCHEDULE'
          });
        }
      });
    }

    if (!paymentDetails?.paymentMethodId) {
      errors.push({
        message: 'Please add payment details',
        section: 'PAYMENT'
      });
    }

    return errors;
  };

  const handleStartTrial = async () => {
    const validationErrors = getValidationErrors();
    if (validationErrors.length > 0) {
      setError(
        <div className="space-y-2">
          {validationErrors.map((error, index) => (
            <div key={index} className="flex items-center justify-between">
              <span>{error.message}</span>
              <Button
                variant="link"
                size="sm"
                onClick={() => onEditSection(error.section)}
                className="text-indigo-600 hover:text-indigo-700"
              >
                Fix this
              </Button>
            </div>
          ))}
        </div>
      );
      return;
    }

    try {
      setIsProcessing(true);
      setError(null);

      // Create the trial subscription (this updates accessStatus to HAS_ACCESS)
      await post('/payments/create-trial-subscription', {
        userId: user._id,
        subscriptionPlan,
        paymentMethodId: paymentDetails.paymentMethodId
      }, true);

      // Complete onboarding (creates children records)
      await post(`/onboarding/${user._id}/complete`, {
        userId: user._id
      }, true);

      // Get fresh user data from auth/verify endpoint
      const token = Cookies.get('token');
      const freshUserData = await verifyToken(token);
      login(freshUserData);  // Update AuthContext with fresh data

      navigate('/upcoming-lessons');

    } catch (err) {
      console.error('Error starting trial:', err);
      setError('Failed to start your trial. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-[80vh] p-6">
      <div className="w-full max-w-4xl space-y-6">
        <h1 className="text-3xl font-bold text-center mb-8">Review Your Selections</h1>

        {/* Subscription Plan Summary */}
        <Card>
          <CardHeader className="flex flex-row items-center justify-between">
            <div>
              <CardTitle>Subscription Plan</CardTitle>
              <CardDescription>Your selected plan and pricing</CardDescription>
            </div>
            <Button 
              variant="outline" 
              size="sm"
              onClick={() => onEditSection('SUBSCRIPTION')}
            >
              <PencilIcon className="h-4 w-4 mr-2" />
              Edit
            </Button>
          </CardHeader>
          <CardContent>
            <div className="space-y-2">
              {subscriptionPlan ? (
                <>
                  <p className="text-lg font-medium">
                    {subscriptionPlan === 'SINGLE' ? 'Single Child Plan' : 'Family Plan'}
                  </p>
                  <p className="text-2xl font-bold">
                    £{subscriptionPlan === 'SINGLE' ? '60' : '90'}/month
                  </p>
                </>
              ) : (
                <p className="text-lg text-gray-500">No plan selected</p>
              )}
            </div>
          </CardContent>
        </Card>

        {/* Children Details Summary */}
        <Card>
          <CardHeader className="flex flex-row items-center justify-between">
            <div>
              <CardTitle>Children Details</CardTitle>
              <CardDescription>Your children and their schedules</CardDescription>
            </div>
            <Button 
              variant="outline" 
              size="sm"
              onClick={() => onEditSection('SCHEDULE')}
            >
              <PencilIcon className="h-4 w-4 mr-2" />
              Edit
            </Button>
          </CardHeader>
          <CardContent>
            <div className="space-y-6">
              {childDetails?.map((child, index) => (
                <div key={index} className="border-b last:border-0 pb-4">
                  <h3 className="font-medium text-lg">{child.name}</h3>
                  <p className="text-gray-600">Year Group: {child.yearGroup}</p>
                  <div className="mt-2">
                    <p className="font-medium">Scheduled Times:</p>
                    {child.scheduledLessons.map((slot, slotIndex) => (
                      <p key={slotIndex} className="text-gray-600">
                        {slot.dayOfWeek.charAt(0).toUpperCase() + slot.dayOfWeek.slice(1)} at {slot.time}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>

        {/* Payment Details Summary */}
        <Card>
          <CardHeader className="flex flex-row items-center justify-between">
            <div>
              <CardTitle>Payment Details</CardTitle>
              <CardDescription>Your saved payment method</CardDescription>
            </div>
            <Button 
              variant="outline" 
              size="sm"
              onClick={() => onEditSection('PAYMENT')}
            >
              <PencilIcon className="h-4 w-4 mr-2" />
              Edit
            </Button>
          </CardHeader>
          <CardContent>
            <div className="flex items-center space-x-2">
              <div>
                <p className="font-medium">
                  {paymentDetails?.cardDetails?.brand?.toUpperCase()} •••• {paymentDetails?.cardDetails?.last4}
                </p>
                <p className="text-sm text-gray-600">
                  Expires {paymentDetails?.cardDetails?.expiryMonth}/{paymentDetails?.cardDetails?.expiryYear}
                </p>
              </div>
            </div>
          </CardContent>
        </Card>

        {error && (
          <Alert 
            variant="destructive" 
            className="mt-4 border-red-200 bg-red-50"
          >
            <AlertDescription>
              {typeof error === 'string' ? error : error}
            </AlertDescription>
          </Alert>
        )}

        <Button 
          onClick={handleStartTrial}
          disabled={isProcessing}
          className="w-full py-6 text-lg bg-indigo-600 hover:bg-indigo-700"
        >
          {isProcessing ? 'Setting up your trial...' : 'Begin Your Free Trial!'}
        </Button>
      </div>
    </div>
  );
}

export default Review;