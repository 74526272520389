import React, { useState, useEffect } from "react";
import baseSway from "../../../../media/base-sway-unscreen.gif";
import celebration from "../../../../media/celebration-unscreen.gif";
import './TutorSingleQuestionPage.css';

function TutorSingleQuestionPage({ quizQuestion, onAnswerSelect, ButtonsAreClickable, studentInput }) {
    //console.log('studentInput in TutorSingleQuestionPage',studentInput);
    // Always call hooks at the top level
    const [selectedAnswer, setSelectedAnswer] = useState("select");
    const [FreeTextInputValue, setFreeTextInputValue] = useState("");
    const [currentVideoSource, setCurrentVideoSource] = useState(baseSway);
    const [correctFeedback, setCorrectFeedback] = useState(false);
    const [incorrectFeedback, setIncorrectFeedback] = useState(false);
    const [buttonsAreClickable, setButtonsAreClickable] = useState(false);
    const [blankBoxAnswers, setBlankBoxAnswers] = useState({});
    const [currentBoxIndex, setCurrentBoxIndex] = useState(0);
    const [droppedLabels, setDroppedLabels] = useState({});
    const [shuffledLabels, setShuffledLabels] = useState([]);
    const [nextBoxId, setNextBoxId] = useState(null);

    // Reset states when question changes
    useEffect(() => {

        setSelectedAnswer("select");
        setFreeTextInputValue("");
        setCurrentVideoSource(baseSway);
        setCorrectFeedback(false);
        setIncorrectFeedback(false);
        setButtonsAreClickable(false);
        setBlankBoxAnswers({});
        setDroppedLabels({});
    }, [quizQuestion]);  // This will run whenever quizQuestion changes

    // this useEffect is to re-render everything in the case that we have consecutive quiz questions being called from the parent of
    // this component to ensure all state is reset


    useEffect(() => {
        if (quizQuestion && quizQuestion.questionType === 'Drag-And-Drop-Question') {
            const labels = quizQuestion.dragBoxes.map(box => box.label);
            setShuffledLabels([...labels].sort(() => Math.random() - 0.5));
            
            // Set initial next box
            const firstBox = quizQuestion.dragBoxes.find(box => box.order === 1);
            if (firstBox) {
                setNextBoxId(firstBox.id);
            }
        }
    }, [quizQuestion]);

    // Update state based on studentInput prop
    useEffect(() => {
        //console.log('studentInput in TutorSingleQuestionPage',studentInput);
        if (studentInput) {
        // Only check correctness if student has submitted
        if (studentInput.isSubmitted) {
            if (studentInput.isCorrect) {
                setCorrectFeedback(true);
                //setCurrentVideoSource(celebration);
                //setTimeout(() => {
                //    setCurrentVideoSource(baseSway);
                //}, 1500);
            } else {
                setIncorrectFeedback(true);
            }
        }

            switch (quizQuestion?.questionType) {
                case 'Multiple-Choice-Question':
                    //setSelectedAnswer(studentInput.value);
                    handleButtonClick(studentInput.input);
                    break;
                case 'Drop-Down-List':
                    setSelectedAnswer(studentInput.input);
                    break;
                case 'Free-Text-Question':
                    setFreeTextInputValue(studentInput.input);
                    break;
                case 'Blank-Box-Question':
                    setBlankBoxAnswers(studentInput.input);
                    break;
                case 'Drag-And-Drop-Question':
                    setDroppedLabels(studentInput.input);
                    // Update shuffledLabels to remove placed labels
                    const placedLabels = Object.values(studentInput.input);
                    const allLabels = quizQuestion.dragBoxes.map(box => box.label);
                    const remainingLabels = allLabels.filter(label => !placedLabels.includes(label));
                    setShuffledLabels(remainingLabels);

                    break;
            }
        }
    }, [studentInput, quizQuestion]);

    // Now conditionally render if quizQuestion is null
    if (!quizQuestion) {
        return (
            <div className="flex justify-center items-center">
                Questions completed! The child should now be directed to the next lesson.
            </div>
        );
    }

    const handleButtonClick = (answer) => {
        //console.log(answer);
        setSelectedAnswer(answer.id);
        const isCorrect = answer.id === quizQuestion.correctAnswer;
        
        if (isCorrect) {
            setCorrectFeedback(true);
        } else {
            setIncorrectFeedback(true);
        }

        onAnswerSelect(isCorrect, answer.id);
        if (isCorrect) {
            setCurrentVideoSource(celebration);
            setTimeout(() => {
                setCurrentVideoSource(baseSway);
            }, 1500);
        }
        setButtonsAreClickable(false);
    };

    const handleFreeTextSubmit = () => {
        const lowerCaseTexts = quizQuestion.answers.map(answer => answer.text.toLowerCase().trim());
        const isCorrect = lowerCaseTexts.includes(FreeTextInputValue.toLowerCase().trim());

        if (isCorrect) {
            setCorrectFeedback(true);
        } else {
            setIncorrectFeedback(true);
        }

        onAnswerSelect(isCorrect, FreeTextInputValue);
        if (isCorrect) {
            setCurrentVideoSource(celebration);
            setTimeout(() => {
                setCurrentVideoSource(baseSway);
            }, 1500);
        }
        setButtonsAreClickable(false);
    };

    const handleDropDownSubmit = () => {
        if (!quizQuestion.answers.some(answer => answer.id === selectedAnswer)) {
            return;
        }

        const isCorrect = selectedAnswer === quizQuestion.correctAnswer;

        if (isCorrect) {
            setCorrectFeedback(true);
        } else {
            setIncorrectFeedback(true);
        }

        onAnswerSelect(isCorrect, selectedAnswer);
        if (isCorrect) {
            setCurrentVideoSource(celebration);
            setTimeout(() => {
                setCurrentVideoSource(baseSway);
            }, 1500);
        }
        setButtonsAreClickable(false);
    };

    const ButtonColour = (selectedAnswer, answer) => {
        let Formatting = 'whitespace-nowrap h-12 px-2 my-2 text-lg hover:bg-yellow-500 text-black border border-2 border-black rounded-lg focus:outline-none';

        let AdditionalFormatting = ''

        if (selectedAnswer === answer.id && answer.id === quizQuestion.correctAnswer) {
            AdditionalFormatting += ' bg-green-300';
        } else if (selectedAnswer === answer.id && answer.id !== quizQuestion.correctAnswer) {
            AdditionalFormatting += ' bg-red-300';
        } else {
            AdditionalFormatting += ' bg-white';
        }

        Formatting += AdditionalFormatting;

        return Formatting;
    };

    const handleBlankBoxInput = (boxId, value) => {
        setBlankBoxAnswers(prev => ({
            ...prev,
            [boxId]: value
        }));
    };

    const handleBlankBoxKeyPress = (e, currentBox) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const nextBox = quizQuestion.blankBoxes.find(box => box.order === currentBox.order + 1);
            if (nextBox) {
                setCurrentBoxIndex(currentBox.order);
                document.getElementById(`box-${nextBox.id}`).focus();
            } else {
                handleBlankBoxSubmit();
            }
        }
    };

    const handleBlankBoxSubmit = () => {
        // Check if all answers are correct, similar to QuestionPage.js
        const allAnswers = quizQuestion.blankBoxes.map(box => ({
            boxId: box.id,
            userAnswer: blankBoxAnswers[box.id] || '',
            correctAnswer: box.answer
        }));

        // Check if each answer is correct
        const isCorrect = quizQuestion.blankBoxes.every(box => 
            blankBoxAnswers[box.id]?.toLowerCase().trim() === box.answer.toLowerCase().trim()
        );

        if (isCorrect) {
            setCorrectFeedback(true);
            setCurrentVideoSource(celebration);
            setTimeout(() => {
                setCurrentVideoSource(baseSway);
            }, 1500);
        } else {
            setIncorrectFeedback(true);
        }

        onAnswerSelect(isCorrect, allAnswers);
        setButtonsAreClickable(false);
    };

    const handleLabelClick = (label) => {
        if (!buttonsAreClickable) return;
        
        // Find the next empty box by order
        const filledBoxIds = Object.keys(droppedLabels);
        const nextBox = quizQuestion.dragBoxes
            .filter(box => !filledBoxIds.includes(box.id))
            .sort((a, b) => a.order - b.order)[0];

        if (nextBox) {
            // Update droppedLabels with the new label
            setDroppedLabels(prev => ({
                ...prev,
                [nextBox.id]: label
            }));

            // Update shuffledLabels
            setShuffledLabels(prev => prev.filter(l => l !== label));

            // Set next box to highlight
            const nextEmptyBox = quizQuestion.dragBoxes
                .filter(box => !filledBoxIds.includes(box.id) && box.id !== nextBox.id)
                .sort((a, b) => a.order - b.order)[0];
            setNextBoxId(nextEmptyBox?.id || null);
        }
    };

    const handleDragBoxSubmit = () => {
        const isCorrect = quizQuestion.dragBoxes.every(box => 
            droppedLabels[box.id] === box.label
        );
        
        if (isCorrect) {
            setCorrectFeedback(true);
            setCurrentVideoSource(celebration);
            setTimeout(() => {
                setCurrentVideoSource(baseSway);
            }, 1500);
        } else {
            setIncorrectFeedback(true);
        }
        
        onAnswerSelect(isCorrect, droppedLabels);
        setButtonsAreClickable(false);
    };

    // Add handleReset function
    const handleReset = () => {
        // Reset droppedLabels to empty
        setDroppedLabels({});
        // Re-shuffle and restore all labels
        const labels = quizQuestion.dragBoxes.map(box => box.label);
        setShuffledLabels([...labels].sort(() => Math.random() - 0.5));
        // Reset nextBoxId to the first box
        const firstBox = quizQuestion.dragBoxes.find(box => box.order === 1);
        if (firstBox) {
            setNextBoxId(firstBox.id);
        }
    };

    return (                  
        <div className="relative flex flex-col h-full w-full">
            <div className="flex flex-col items-center">
                {/* Only show this div if there's an image */}
                {quizQuestion.image && quizQuestion.image.trim() !== '' ? (
                    // removed border border-red-500 from div below as no longer testing
                    <div className="w-full text-center text-2xl font-bold">
                        <p>{quizQuestion.question}</p>
                    </div>
                ) : null}

                {/* This div will either contain the image or the question text */}
                {quizQuestion.questionType !== 'Blank-Box-Question' && quizQuestion.questionType !== 'Drag-And-Drop-Question' 
                  && (
                    <div className=" w-full h-[30%] flex justify-center items-center">
                        {quizQuestion.image && quizQuestion.image.trim() !== '' ? (
                            <img src={quizQuestion.image} alt="Question" className="max-w-full max-h-full object-contain" />
                        ) : (
                            <p className="text-2xl font-bold text-center px-4">{quizQuestion.question}</p>
                        )}
                    </div>
                )}

                {correctFeedback && (
                    <div className="w-full text-center border-2 border-green-600 rounded-lg correct-feedback">
                        <p>Correct!</p>
                        {quizQuestion.correctFeedback && <p>{quizQuestion.correctFeedback}</p>}
                    </div>
                )}      
                                 
                {incorrectFeedback && (
                    <div className="w-full text-center border-2 border-red-600 rounded-lg incorrect-feedback">
                        <p>Incorrect!</p>
                        {quizQuestion.incorrectFeedback && <p>{quizQuestion.incorrectFeedback}</p>}
                    </div>
                )}

              {quizQuestion.questionType === 'Multiple-Choice-Question' ? (
                    <div className="w-full flex flex-wrap justify-between z-20">
                        {quizQuestion.answers.map((answer) => (
                            <button
                                key={answer.id}
                                onClick={buttonsAreClickable ? () => handleButtonClick(answer) : undefined}
                                className={ButtonColour(selectedAnswer, answer)}
                                style={{ width: '48%', whiteSpace: 'normal', height: 'auto' }}
                            >
                                {answer.text}
                            </button>
                        ))}
                    </div>
                ) : quizQuestion.questionType === 'Drop-Down-List' ? (
                    <main className="drop-down-container">
                        <select
                            value={selectedAnswer}
                            onChange={(e) => { setSelectedAnswer(e.target.value); }}
                            className="form__border w-full my-2"
                            disabled={true}
                        >
                            <option value="select">Select Answer</option>
                            {quizQuestion.answers.map((answer) => (
                                <option key={answer.id} value={answer.id}>
                                    {answer.text}
                                </option>
                            ))}
                        </select>
                        <button
                            onClick={buttonsAreClickable ? handleDropDownSubmit : undefined}
                            className="submit-button"
                        >
                            Submit
                        </button>
                    </main>
                ) : quizQuestion.questionType === 'Free-Text-Question' ? (
                    <div className="w-full flex flex-col items-center justify-center my-2">
                        <input
                            type="text"
                            value={FreeTextInputValue}
                            onChange={(e) => { setFreeTextInputValue(e.target.value); }}
                            placeholder="Enter answer here"
                            className="w-full h-12 p-3 text-lg border-2 border-gray-600 rounded-lg focus:outline-none focus:border-blue-500"
                            disabled={true}
                        />
                        <button
                            onClick={buttonsAreClickable ? handleFreeTextSubmit : undefined}
                            className="submit-button w-32"
                        >
                            Submit
                        </button>
                    </div>
                ) : quizQuestion.questionType === 'Blank-Box-Question' ? (
                    // removed border border-purple-500 from div below as no longer testing
                    <div className="relative w-full h-[45%] flex justify-center items-center ">
                        <div className="relative h-full w-full flex justify-center items-center">
                            <div className="relative h-full" style={{ maxWidth: '100%' }}>
                                <img 
                                    src={quizQuestion.image} 
                                    alt="Question" 
                                    className="h-full w-auto object-contain max-w-full"
                                    draggable="false"
                                />
                                {quizQuestion.blankBoxes.map((box) => (
                                    <div
                                        key={box.id}
                                        className="absolute"
                                        style={{
                                            left: `${box.x}%`,
                                            top: `${box.y}%`,
                                            width: `${box.width}%`,
                                            height: `${box.height}%`
                                        }}
                                    >
                                        <input
                                            id={`box-${box.id}`}
                                            type="text"
                                            value={blankBoxAnswers[box.id] || ''}
                                            onChange={(e) => handleBlankBoxInput(box.id, e.target.value)}
                                            onKeyPress={(e) => handleBlankBoxKeyPress(e, box)}
                                            className={`w-full h-full p-1 border-2 ${
                                                blankBoxAnswers[box.id] 
                                                    ? blankBoxAnswers[box.id]?.toLowerCase().trim() === box.answer.toLowerCase().trim()
                                                        ? 'border-green-500 bg-green-100'
                                                        : 'border-red-500 bg-red-100'
                                                    : 'border-gray-400 bg-gray-200'  // Default state when no answer
                                            }`}
                                            disabled={true}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : quizQuestion.questionType === 'Drag-And-Drop-Question' ? (
                    //removed border border-orange-500 from div below as no longer testing
                    <div className="relative w-full h-[45%] flex justify-center items-center "> 
                        {/* Image container */}
                        <div className="relative h-full w-full flex justify-center items-center">
                            <div className="relative h-full" style={{ maxWidth: '100%' }}>
                                <img 
                                    src={quizQuestion.image} 
                                    alt="Question" 
                                    className="h-full w-auto object-contain"
                                    draggable="false"
                                />
                                {quizQuestion.dragBoxes.map((box) => (
                                    <div
                                        key={box.id}
                                        className={`absolute border-2 ${
                                            droppedLabels[box.id] 
                                                ? droppedLabels[box.id] === box.label
                                                    ? 'border-green-500 bg-green-100'
                                                    : 'border-red-500 bg-red-100'
                                                : 'border-gray-400 bg-gray-200'  // Default state when no label is dropped
                                        }`}
                                        style={{
                                            left: `${box.x}%`,
                                            top: `${box.y}%`,
                                            width: `${box.width}%`,
                                            height: `${box.height}%`
                                        }}
                                    >
                                        {droppedLabels[box.id] && (
                                            <div className="w-full h-full flex items-center justify-center">
                                                <div className="p-2 bg-blue-500 text-white rounded">
                                                    {droppedLabels[box.id]}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                     </div>
                ) : null}

                {quizQuestion.questionType === 'Drag-And-Drop-Question' && (
                    <div className="flex">
                        <div className="mt-2 flex flex-wrap justify-center gap-2 z-20">
                            {shuffledLabels.map((label, index) => (
                                <div
                                    key={index}
                                    className="p-2 bg-blue-500 text-white rounded cursor-pointer hover:bg-blue-600"
                                    onClick={() => handleLabelClick(label)}
                                >
                                    {label}
                                </div>
                            ))}
                        </div>
                        <div className="mt-2 flex mx-2">
                            {buttonsAreClickable && Object.keys(droppedLabels).length > 0 && (
                                <button
                                    onClick={handleReset}
                                    className="bg-yellow-500 text-black px-4 py-2 rounded hover:bg-yellow-600"
                                >
                                    Reset Labels
                                </button>
                            )}
                            {buttonsAreClickable && shuffledLabels.length === 0 && (
                                <button
                                    onClick={handleDragBoxSubmit}
                                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                                >
                                    Submit
                                </button>
                            )}

                        </div>
                    </div>
               )}
                {quizQuestion.questionType === 'Blank-Box-Question' && buttonsAreClickable && (
                    <button
                        onClick={handleBlankBoxSubmit}
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    >
                        Submit
                    </button>
                )}
            </div>
        </div>
    );
}

export default TutorSingleQuestionPage;