import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter
} from '@/components/shadcn/card';
import { Button } from '@/components/shadcn/button';
import { Input } from '@/components/shadcn/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/shadcn/select";

import { get, post } from '../../../utils/api';

import { AlertCircle, X } from 'lucide-react';

import { Alert, AlertTitle, AlertDescription } from "@/components/shadcn/alert";
import { PlusCircle } from 'lucide-react';

function ChildDetails({ onComplete, subscriptionPlan, childDetails }) {
  //console.log('childDetails from backend:', childDetails);
  
  const [children, setChildren] = useState(
    childDetails && childDetails.length > 0 
      ? childDetails.map(child => ({
          name: child.name,
          yearGroup: child.yearGroup,
          dateOfBirth: child.dateOfBirth,
          scheduledLessons: child.scheduledLessons.map(lesson => ({
              day: lesson.dayOfWeek.charAt(0).toUpperCase() + lesson.dayOfWeek.slice(1),
              time: lesson.time,
              lessonScheduleId: lesson._id
          }))
      }))
      : [{
          name: '',
          yearGroup: '',
          dateOfBirth: '',
          scheduledLessons: [
            { day: '', time: '', lessonScheduleId: null },
            { day: '', time: '', lessonScheduleId: null }
          ]
      }]
  );

  const [error, setError] = useState('');
  const [availableDays, setAvailableDays] = useState([]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState({});
  const [loading, setLoading] = useState(true);
  const [availableSchedules, setAvailableSchedules] = useState([]);
  const [showPlanAlert, setShowPlanAlert] = useState(false);

  const yearGroups = [
    'Year 2', 'Year 3', 'Year 4', 'Year 5', 'Year 6'
  ];

  useEffect(() => {
    const fetchSchedules = async () => {
      try {
        const response = await get('/lesson-schedules/available');
        setAvailableSchedules(response);
        
        // Get unique days
        const days = [...new Set(response.map(schedule => schedule.day))];
        setAvailableDays(days);

        // Create a map of day -> available times with their IDs
        const timeSlotMap = response.reduce((acc, schedule) => {
          acc[schedule.day] = schedule.timeSlots.map(time => ({
            time: time,
            scheduleId: schedule._id
          }));
          return acc;
        }, {});
        setAvailableTimeSlots(timeSlotMap);

        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch schedules:', error);
        setLoading(false);
      }
    };

    fetchSchedules();
  }, []);

  const timeSlots = [
    '9:00 AM', '10:00 AM', '11:00 AM', '2:00 PM', 
    '3:00 PM', '4:00 PM', '5:00 PM'
  ];

  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  const times = ['17:00', '17:30', '18:00', '18:30', '19:00', '19:30'];

  const handleAddChild = () => {
    if (subscriptionPlan === 'SINGLE') {
      setShowPlanAlert(true);
      return;
    }
    
    if (children.length < 4) {
      setChildren([...children, {
        name: '',
        yearGroup: '',
        dateOfBirth: '',
        scheduledLessons: [
          { day: '', time: '', lessonScheduleId: null },
          { day: '', time: '', lessonScheduleId: null }
        ]
      }]);
    }
  };

  const validateDateOfBirth = (date) => {
    const today = new Date();
    const inputDate = new Date(date);
    return inputDate < today;
  };

  const handleChildChange = (index, field, value) => {
    if (field === 'dateOfBirth' && !validateDateOfBirth(value)) {
      setError('Date of birth must be in the past');
      return;
    }

    const updatedChildren = [...children];
    updatedChildren[index] = {
      ...updatedChildren[index],
      [field]: value
    };
    setChildren(updatedChildren);
    setError('');
  };

  const handleScheduleChange = (childIndex, slotIndex, field, value) => {
    const updatedChildren = [...children];
    const slot = updatedChildren[childIndex].scheduledLessons[slotIndex];
    
    if (field === 'day') {
      slot.day = value;
      slot.time = ''; // Reset time when day changes
      slot.lessonScheduleId = null;
    } else if (field === 'time') {
      slot.time = value;
      // Find the schedule ID for this day/time combination
      const daySchedule = availableSchedules.find(s => s.day === slot.day);
      const timeSlot = daySchedule?.timeSlots.find(t => t.time === value);
      slot.lessonScheduleId = timeSlot?._id;
    }

    setChildren(updatedChildren);
  };

  const hasDuplicateSlots = (child) => {
    const slots = child.scheduledLessons;
    return slots.some((slot1, index1) => 
      slots.some((slot2, index2) => 
        index1 !== index2 && 
        slot1.day === slot2.day && 
        slot1.time === slot2.time &&
        slot1.day && slot1.time  // Only check if both fields are filled
      )
    );
  };

  const handleContinue = () => {
    // Check for duplicate slots
    const childWithDuplicates = children.find(hasDuplicateSlots);
    if (childWithDuplicates) {
      setError(`${childWithDuplicates.name} has duplicate lesson slots. Please choose different times.`);
      return;
    }

    const isValid = children.every(child => 
      child.name && 
      child.yearGroup && 
      child.dateOfBirth &&
      child.scheduledLessons.every(slot => slot.day && slot.time && slot.lessonScheduleId)
    );

    if (isValid) {
      setError('');  // Clear any existing errors
      onComplete({
        children: children.map(child => ({
          name: child.name,
          yearGroup: child.yearGroup,
          dateOfBirth: child.dateOfBirth,
          scheduledLessons: child.scheduledLessons
        }))
      });
    } else {
      setError('Please fill in all required fields for each child.');
    }
  };

  const handleRemoveChild = (indexToRemove) => {
    setChildren(children.filter((_, index) => index !== indexToRemove));
  };

  return (
    <div className="flex flex-col items-center min-h-screen p-6 pt-0">
      {loading ? (
        <div>Loading available schedules...</div>
      ) : (
        <div className="w-full max-w-4xl space-y-6">


          {children.map((child, childIndex) => (
            <Card key={childIndex} className="w-full relative">
              {childIndex > 0 && (
                <Button
                  variant="ghost"
                  size="icon"
                  className="absolute top-4 right-4 hover:bg-red-100"
                  onClick={() => handleRemoveChild(childIndex)}
                >
                  <X className="h-4 w-4 text-red-500" />
                  <span className="sr-only">Remove Child</span>
                </Button>
              )}
              
              <CardHeader>
                <CardTitle>Child {childIndex + 1} Details</CardTitle>
                <CardDescription>
                  Please enter your child's information and preferred schedule
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-2">
                    <label className="text-sm font-medium">Full Name</label>
                    <Input
                      value={child.name}
                      onChange={(e) => handleChildChange(childIndex, 'name', e.target.value)}
                      placeholder="Enter child's full name"
                    />
                  </div>
                  <div className="space-y-2">
                    <label className="text-sm font-medium">Year Group</label>
                    <Select
                      value={child.yearGroup}
                      onValueChange={(value) => handleChildChange(childIndex, 'yearGroup', value)}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select year group" />
                      </SelectTrigger>
                      <SelectContent>
                        {yearGroups.map((year) => (
                          <SelectItem key={year} value={year}>
                            {year}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="space-y-2">
                    <label className="text-sm font-medium">Date of Birth</label>
                    <Input
                      type="date"
                      value={child.dateOfBirth}
                      onChange={(e) => handleChildChange(childIndex, 'dateOfBirth', e.target.value)}
                      max={new Date().toISOString().split('T')[0]}
                    />
                  </div>
                </div>

                <div className="space-y-4">
                  <h4 className="font-medium">Choose your child's lesson times</h4>
                  <p className="text-sm text-gray-500">Please select 2 slots during the week for your child to attend lessons. Each lesson is 30 minutes long.</p>
                  
                  <div className="space-y-4">
                    {child.scheduledLessons.map((slot, slotIndex) => (
                      <div key={slotIndex} className="p-4 bg-gray-50 rounded-lg">
                        <h5 className="font-bold mb-4 text-lg">Slot {slotIndex + 1}</h5>
                        
                        <div className="grid grid-cols-2 gap-4">
                          <div className="space-y-2">
                            <label className="text-sm font-medium">Day of Week</label>
                            <Select
                              value={slot.day}
                              onValueChange={(value) => 
                                handleScheduleChange(childIndex, slotIndex, 'day', value)
                              }
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select day" />
                              </SelectTrigger>
                              <SelectContent>
                                {availableDays.map((day) => (
                                  <SelectItem key={day} value={day}>
                                    {day}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          </div>

                          <div className="space-y-2">
                            <label className="text-sm font-medium">Time</label>
                            <Select
                              value={slot.time}
                              onValueChange={(value) => 
                                handleScheduleChange(childIndex, slotIndex, 'time', value)
                              }
                              disabled={!slot.day}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select time" />
                              </SelectTrigger>
                              <SelectContent>
                                {slot.day && availableSchedules
                                  .find(s => s.day === slot.day)
                                  ?.timeSlots.map(timeSlot => (
                                    <SelectItem key={timeSlot._id} value={timeSlot.time}>
                                      {timeSlot.time}
                                    </SelectItem>
                                  ))}
                              </SelectContent>
                            </Select>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
          
          {error && (
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {showPlanAlert && (
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>Error</AlertTitle>
              <AlertDescription>
                To add more children, please choose the Family Plan in the previous section.
              </AlertDescription>
            </Alert>
          )}

          <div className="w-full bg-amber-50 border-2 border-amber-500 rounded-xl p-6 shadow-lg">
            <h2 className="text-xl font-bold text-amber-700 mb-2">
              Important Notice for Multiple Children
            </h2>
            <p className="text-amber-700">
              If you are scheduling lessons for multiple children at the same day and time:
            </p>
            <ul className="list-disc list-inside mt-2 text-amber-700 space-y-1">
              <li>Each child must have their own separate device (laptop/tablet) to access their lesson</li>
              <li>If this isn't possible, please schedule your children's lessons for different time slots</li>
            </ul>
          </div>

          {children.length < 4 && (
            <Button
              onClick={handleAddChild}
              variant="outline"
              className="w-full"
            >
              <PlusCircle className="mr-2 h-4 w-4" />
              Add Another Child
            </Button>
          )}

          <Button 
            onClick={handleContinue}
            disabled={!children.every(child => 
              child.name && 
              child.yearGroup && 
              child.dateOfBirth &&
              child.scheduledLessons.every(slot => slot.day && slot.time)
            )}
            className="w-full py-6 text-lg bg-indigo-600 hover:bg-indigo-700"
          >
            Continue
          </Button>
        </div>
      )}
    </div>
  );
}

export default ChildDetails;