import { useCallback, useEffect, useRef } from 'react';
import { useDaily, useParticipantIds } from '@daily-co/daily-react';
import { useAuth } from '../../../contexts/AuthContext';

import handRaisedSound from '../../../media/music/hand-raised-effect.mp3';

export const HAND_RAISED_USER_DATA_KEY = 'hr';

export const useHandRaising = (tutorEmails) => {
  const daily = useDaily();

  const { user } = useAuth();
  const prevParticipantsRef = useRef([]);
  

  const handRaiseSound = new Audio(handRaisedSound);
  

  const handRaisedParticipants = useParticipantIds({
    filter: useCallback((participant) => 
      participant.userData?.[HAND_RAISED_USER_DATA_KEY], 
    []),
  });

  // Play sound only when new hands are raised
  useEffect(() => {

    if (!tutorEmails?.includes(user?.email)) return;
    
    // Find new hand raises by checking which IDs weren't in the previous list
    const newHandRaises = handRaisedParticipants.filter(
      id => !prevParticipantsRef.current.includes(id)
    );

    // Only play sound if there are new hand raises
    if (newHandRaises.length > 0) {
      handRaiseSound.play().catch(err => console.log('Audio play failed:', err));
    }

    // Update previous state for next comparison
    prevParticipantsRef.current = handRaisedParticipants;
  }, [handRaisedParticipants, tutorEmails, user?.email]);

  const raiseHand = useCallback(() => {
    const prevUserData = daily?.participants()?.local?.userData;

    daily.setUserData({
      ...prevUserData,
      [HAND_RAISED_USER_DATA_KEY]: new Date().toISOString(),
    });
  }, [daily]);

  const lowerHand = useCallback(() => {
    const prevUserData = {
      ...daily?.participants()?.local?.userData
    };
    delete prevUserData[HAND_RAISED_USER_DATA_KEY];

    daily.setUserData({ ...prevUserData });
  }, [daily]);

  return {
    raiseHand,
    lowerHand,
    handRaisedParticipants
  };
};

export const useHandRaisedQueue = (id) => {
  const idsWithRaisedHands = useParticipantIds({
    filter: useCallback((p) => 
      p.userData?.[HAND_RAISED_USER_DATA_KEY], 
    []),
    sort: useCallback((a, b) => {
      const aDate = a.userData?.[HAND_RAISED_USER_DATA_KEY];
      const bDate = b.userData?.[HAND_RAISED_USER_DATA_KEY];

      if (aDate < bDate) return -1;
      if (aDate > bDate) return 1;
      return 0;
    }, []),
  });

  if (idsWithRaisedHands.includes(id)) {
    return idsWithRaisedHands.indexOf(id) + 1;
  }

  return undefined;
};