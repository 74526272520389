import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { get, post } from '../utils/api';
import { Button } from './shadcn/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./shadcn/dialog";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "./shadcn/card";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./shadcn/alert-dialog";
import { UpdatePaymentMethod } from './UpdatePaymentMethod';
//import { useToast } from "./shadcn/use-toast";
import { useToast } from "@/components/hooks/use-toast"

import { Toaster } from "./shadcn/toaster";

export function SubscriptionManagement() {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPlanDialog, setShowPlanDialog] = useState(false);
  const [targetPlan, setTargetPlan] = useState(null);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showReactivateDialog, setShowReactivateDialog] = useState(false);
  const [showUndoDowngradeDialog, setShowUndoDowngradeDialog] = useState(false);
  const [showUpdatePayment, setShowUpdatePayment] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [retryingPayment, setRetryingPayment] = useState(false);
  const { user } = useAuth();
  const { toast } = useToast();
  const [planPrices, setPlanPrices] = useState({});

  useEffect(() => {
    //console.log('this is the user', user);
    loadSubscriptionDetails();
  }, [user?.userId]);

  useEffect(() => {
    const loadPrices = async () => {
      try {
        const familyPrice = await get(`/payments/product-details/FAMILY`, true);
        const singlePrice = await get(`/payments/product-details/SINGLE_CHILD`, true);
        setPlanPrices({
          family: familyPrice.price,
          single_child: singlePrice.price
        });
      } catch (err) {
        console.error('Failed to load plan prices:', err);
      }
    };
    
    loadPrices();
  }, []);

  const loadSubscriptionDetails = async () => {
    try {
      const data = await get(`/payments/subscription/${user?._id}`, true);
      setSubscription(data);
    } catch (err) {
      setError('Failed to load subscription details');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      await post('/payments/subscription/cancel', { userId: user?._id }, true);
      await loadSubscriptionDetails();
    } catch (err) {
      setError('Failed to cancel subscription');
      console.error(err);
    }
  };

  const handleReactivateSubscription = async () => {
    try {
      await post('/payments/subscription/reactivate', { userId: user?._id }, true);
      await loadSubscriptionDetails();
    } catch (err) {
      setError('Failed to reactivate subscription');
      console.error(err);
    }
  };

  const handleUpdatePlan = async (newPlan) => {
    try {
      await post('/payments/subscription/update-plan', {
        userId: user?._id,
        newPlan
      }, true);
      await loadSubscriptionDetails();
    } catch (err) {
      setError('Failed to update subscription plan');
      console.error(err);
    }
  };

  const handleUpdatePlanClick = (newPlan) => {
    setTargetPlan(newPlan);
    setShowPlanDialog(true);
  };

  const handleConfirmPlanUpdate = async () => {
    try {
      await handleUpdatePlan(targetPlan);
      setShowPlanDialog(false);
    } catch (err) {
      setError('Failed to update plan');
    }
  };

  const handleCancelClick = () => {
    setShowCancelDialog(true);
  };

  const handleReactivateClick = () => {
    setShowReactivateDialog(true);
  };

  const handleConfirmCancel = async () => {
    try {
      await handleCancelSubscription();
      setShowCancelDialog(false);
    } catch (err) {
      setError('Failed to cancel subscription');
    }
  };

  const handleConfirmReactivate = async () => {
    try {
      await handleReactivateSubscription();
      setShowReactivateDialog(false);
    } catch (err) {
      setError('Failed to reactivate subscription');
    }
  };

  const handleUndoDowngradeClick = () => {
    setShowUndoDowngradeDialog(true);
  };

  const handleConfirmUndoDowngrade = async () => {
    try {
      await post('/payments/subscription/undo-downgrade', {
        userId: user?._id
      }, true);
      setShowUndoDowngradeDialog(false);
      await loadSubscriptionDetails();
    } catch (err) {
      setError('Failed to undo plan downgrade');
    }
  };

  const handleRetryPayment = async () => {
    try {
      setRetryingPayment(true);
      await post('/payments/retry-payment', { 
        userId: user?._id 
      }, true);
      
      // Refresh subscription details
      await loadSubscriptionDetails();
      
      // Show success message
      toast({
        title: "Payment Successful",
        description: "Your payment has been processed successfully.",
        duration: 5000,
      });
    } catch (err) {
      console.error('Failed to process payment:', err);
      // Show error message
      toast({
        title: "Payment Failed",
        description: "Please check your payment method and try again.",
        variant: "destructive",
        duration: 5000,
      });
    } finally {
      setRetryingPayment(false);
    }
  };

  // Helper function for plan display names
  const getPlanDisplayName = (plan) => {
    if (!plan) return '';
    switch (plan) {
      case 'family':
        return 'Family';
      case 'single_child':
        return 'Single Child';
      default:
        return '';
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!subscription) return <div>No subscription found</div>;

  return (
    <div className="max-w-4xl mx-auto p-6">
      <Card>
        <CardHeader>
          <CardTitle>Your Subscription</CardTitle>
          <CardDescription>Manage your subscription details and billing</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            {/* Current Plan Details */}
            <div className="space-y-2">
              <h3 className="text-lg font-semibold">Current Plan</h3>
              
              {/* Add payment failed message */}
              {subscription.status === 'past_due' && (
                <div className="mt-2 p-4 bg-red-50 border border-red-200 rounded-md">
                  <p className="text-red-700 font-medium">
                    Your subscription payment failed. Please update your card in the Payment Method section below then click 'Re-attempt Payment' to pay and regain access.
                  </p>
                  <Button 
                    className="mt-2"
                    variant="default"
                    onClick={handleRetryPayment}
                    disabled={retryingPayment}
                  >
                    {retryingPayment ? 'Processing...' : 'Re-attempt Payment'}
                  </Button>
                </div>
              )}

              <p>Status: <span className="capitalize">{subscription.status}</span></p>
              <p>Plan: {subscription.plan}</p>
              
              {subscription.schedule && (
                <div className="mt-2 p-3 bg-amber-50 border border-amber-200 rounded-md">
                  <p className="text-amber-700">
                    Your plan will change to {subscription.schedule.phases[1].items[0].price === process.env.STRIPE_FAMILY_PLAN_PRICE_ID ? 'Family' : 'Single Child'} on{' '}
                    {new Date(subscription.schedule.phases[1].start_date * 1000).toLocaleDateString()}
                  </p>
                </div>
              )}

              {/* Don't need the below as we're using subscription.schedule now */}
              {/*subscription.pendingUpdate && (
                <div className="mt-2 p-3 bg-amber-50 border border-amber-200 rounded-md">
                  <p className="text-amber-700">
                    Your plan will change to {subscription.pendingUpdate.newPlan === 'FAMILY' ? 'Family' : 'Single Child'} on{' '}
                    {new Date(subscription.pendingUpdate.effectiveDate).toLocaleDateString()}
                  </p>
                </div>
              )*/}

              {subscription.cancelAtPeriodEnd ? (
                <span className="text-amber-600">
                  Access until: {new Date(subscription.currentPeriodEnd).toLocaleDateString()}
                  <br />
                  <span className="text-sm">
                    Your subscription will not renew after this date
                  </span>
                </span>
              ) : (
                <span>
                  Next renewal: {new Date(subscription.currentPeriodEnd).toLocaleDateString()}
                </span>
              )}
              
              {subscription.trialEnd && (
                <p>Trial Ends: {new Date(subscription.trialEnd).toLocaleDateString()}</p>
              )}
            </div>

            {/* Payment Method Section */}
            <div className="space-y-2">
              <h3 className="text-lg font-semibold">Payment Method</h3>
              
              {/* Show warning for failed payments */}
              {/* subscription.status === 'past_due' && (
                <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-md">
                  <p className="text-red-700 font-medium">
                    Your subscription payment failed. Please update your card and attempt to pay again to regain access.
                  </p>
                </div>
              )*/}

              {/* Always show current payment method and update button */}
              <div className="flex items-center justify-between p-4 bg-gray-50 rounded-md">
                <div>
                  <p className="font-medium">Current Payment Method</p>
                  <p className="text-sm text-gray-600">
                    {subscription.paymentMethod ? (
                      `•••• •••• •••• ${subscription.paymentMethod.last4}`
                    ) : (
                      'No payment method on file'
                    )}
                  </p>
                </div>
                <Button 
                  variant=""
                  onClick={() => setShowUpdatePayment(true)}
                >
                  Update Payment Method
                </Button>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="space-x-4">
              {(subscription.status === 'active' || subscription.status === 'trialing') && !subscription.cancelAtPeriodEnd && !subscription.pendingUpdate && (
                <Button 
                  variant="destructive"
                  onClick={handleCancelClick}
                >
                  Cancel {subscription.status === 'trialing' ? 'Trial' : 'Subscription'}
                </Button>
              )}

  {/*NEED TO ADD IN THE CASE WEHRE A SUBSCRIPTION HAS ALREADY ACTUALLY BEEN CANCELLED AND IS NOT JUST PENDDING CANCELATION AT PERIOD END*/}
              {subscription.cancelAtPeriodEnd && (
                <Button 
                  variant="default"
                  onClick={handleReactivateClick}
                >
                  Reactivate Subscription
                </Button>
              )}
              {/*(subscription.status === 'active' || subscription.status === 'trialing') && 
               !subscription.cancelAtPeriodEnd && 
               !subscription.pendingUpdate && 
               !subscription.schedule && (
                <Button
                  variant=""
                  onClick={() => handleUpdatePlanClick(
                    subscription.plan === 'family' ? 'SINGLE_CHILD' : 'FAMILY'
                  )}
                >
                  Switch to {subscription.plan === 'family' ? 'Single Child' : 'Family'} Plan
                </Button>
              )*/}
              {/* labelling the button as 'Undo pending Downgrade' works because we only schedule for downgrades*/}
              {subscription.schedule && (
                <Button
                  variant=""
                  onClick={handleUndoDowngradeClick}
                >
                  Undo pending Downgrade
                </Button>
              )}
            </div>

            {/* Payment History */}
            <div className="mt-8">
              <h3 className="text-lg font-semibold mb-4">Payment History</h3>
              <div className="space-y-2">
                {subscription.paymentHistory?.map(payment => (
                  <div 
                    key={payment.id}
                    className="flex justify-between items-center p-3 bg-gray-50 rounded"
                  >
                    <span>{new Date(payment.date).toLocaleDateString()}</span>
                    <span>£{payment.amount.toFixed(2)}</span>
                    <span className="capitalize">{payment.status}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Cancel Dialog */}
      <Dialog open={showCancelDialog} onOpenChange={setShowCancelDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Cancel {subscription?.status === 'trialing' ? 'Trial' : 'Subscription'}?</DialogTitle>
            <DialogDescription>
              <div className="space-y-4">
                {subscription?.status === 'trialing' ? (
                  <p>
                    Are you sure you want to cancel your trial? 
                    <p className="mt-2">
                      If you cancel, you'll still have access until {new Date(subscription.trialEnd).toLocaleDateString()}, but after that you'll lose access to:
                    </p>
                    <ul className="list-disc pl-5 mt-2">
                      <li>Live, personalised classroom sessions with our tutor support</li>
                      <li>Interactive lessons and quizzes</li>
                      <li>Child Progress tracking</li>
                    </ul>
                  </p>
                ) : (
                  <p>
                    Are you sure you want to cancel your subscription? You'll continue to have access until:
                    <p className="font-semibold mt-2">
                      {new Date(subscription?.currentPeriodEnd).toLocaleDateString()}
                    </p>
                    <p className="text-sm text-gray-500 mt-2">
                      But after this date, you'll lose access to:
                    </p>
                    <ul className="list-disc pl-5 mt-2">
                      <li>Live, personalised classroom sessions with our tutor support</li>
                      <li>Interactive lessons and quizzes</li>
                      <li>Child Progress tracking</li>
                    </ul>
                  </p>
                )}
              </div>
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="space-x-4">
            <Button
              variant="outline"
              onClick={() => setShowCancelDialog(false)}
            >
              Keep Subscription
            </Button>
            <Button
              variant="destructive"
              onClick={handleConfirmCancel}
            >
              Yes, Cancel
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Reactivate Dialog */}
      <Dialog open={showReactivateDialog} onOpenChange={setShowReactivateDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Reactivate Subscription</DialogTitle>
            <DialogDescription>
              <div className="space-y-4">
                <p>
                  Great to have you back! By reactivating your subscription:
                </p>
                <ul className="list-disc pl-5">
                  <li>Your {getPlanDisplayName(subscription?.plan)} plan will continue</li>
                  <li>You'll be billed £{planPrices[subscription?.plan]} on {new Date(subscription?.currentPeriodEnd).toLocaleDateString()}</li>
                  <li>You'll get immediate access to all features</li>
                </ul>
              </div>
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="space-x-4">
            <Button
              variant="outline"
              onClick={() => setShowReactivateDialog(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmReactivate}
            >
              Reactivate Subscription
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Plan Switch Dialog */}
      <Dialog open={showPlanDialog} onOpenChange={setShowPlanDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Plan Change</DialogTitle>
            <DialogDescription>
              {subscription?.status === 'trialing' ? (
                // Trial period messaging
                <div className="space-y-4">
                  <p>
                    You're currently in your trial period. Switching to the {targetPlan === 'family' ? 'family' : 'single_child'} plan
                    will take effect after your trial ends.
                  </p>
                  <p className="font-semibold">
                    After your trial, you'll be billed £{planPrices[targetPlan]} per month
                  </p>
                  <p className="text-sm text-gray-500">
                    Trial ends: {new Date(subscription.trialEnd).toLocaleDateString()}
                  </p>
                </div>
              ) : (
                // Regular subscription messaging
                <div className="space-y-4">
                  <p>
                    You're about to switch from the {getPlanDisplayName(subscription?.plan)} plan
                    (£{planPrices[subscription?.plan]}/month) to the {getPlanDisplayName(targetPlan)} plan
                    (£{planPrices[targetPlan]}/month).
                  </p>
                  <p>
                    {planPrices[targetPlan] > planPrices[subscription?.plan]
                      ? 'You will be charged the difference immediately.'
                      : 'The new price will take effect in your next billing cycle.'}
                  </p>
                  <p className="text-sm text-gray-500">
                    Next billing date: {new Date(subscription.currentPeriodEnd).toLocaleDateString()}
                  </p>
                </div>
              )}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="space-x-4">
            <Button
              variant="outline"
              onClick={() => setShowPlanDialog(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmPlanUpdate}
            >
              Confirm Change
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Undo Downgrade Dialog */}
      <Dialog open={showUndoDowngradeDialog} onOpenChange={setShowUndoDowngradeDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Cancel Planned Downgrade?</DialogTitle>
            <DialogDescription>
              <div className="space-y-4">
                <p>
                  You're about to cancel your scheduled plan change. This means:
                </p>
                <ul className="list-disc pl-5">
                  <li>You'll keep your current Family plan</li>
                  <li>You'll continue to be billed £{planPrices['family']} per month</li>
                  <li>Your planned downgrade to the Single Child plan will be cancelled</li>
                </ul>
                <p className="text-sm text-gray-500">
                  Next billing date: {new Date(subscription.currentPeriodEnd).toLocaleDateString()}
                </p>
              </div>
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="space-x-4">
            <Button
              variant="outline"
              onClick={() => setShowUndoDowngradeDialog(false)}
            >
              Keep Planned Downgrade
            </Button>
            <Button
              onClick={handleConfirmUndoDowngrade}
            >
              Cancel Downgrade
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <UpdatePaymentMethod 
        isOpen={showUpdatePayment}
        onClose={() => setShowUpdatePayment(false)}
        onSuccess={() => {
          setShowUpdatePayment(false);
          loadSubscriptionDetails();
          setShowSuccessDialog(true);
        }}
        customerId={user?.stripeCustomerId}
        defaultPaymentMethodId={subscription?.paymentMethod?.id}
      />

      {/* Success Dialog */}
     <AlertDialog open={showSuccessDialog} onOpenChange={setShowSuccessDialog}>
       <AlertDialogContent>
         <AlertDialogHeader>
           <AlertDialogTitle>Payment Method Updated</AlertDialogTitle>
           <AlertDialogDescription>
             Your default payment method has been successfully updated.
           </AlertDialogDescription>
         </AlertDialogHeader>
         <AlertDialogFooter>
           <Button onClick={() => setShowSuccessDialog(false)}>
             OK
           </Button>
         </AlertDialogFooter>
       </AlertDialogContent>
     </AlertDialog>
    </div>
  );
}