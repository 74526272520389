import React, { useState, useRef, useEffect, useContext } from 'react';
import { get, post, put } from '../utils/api';
import { useParams, useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import SingleQuestionPage from './Quiz/SingleQuestionPage/SingleQuestionPage';
import { AuthContext } from '../contexts/AuthContext';
import { logout } from '../services/authService';
import { useAuth } from '../contexts/AuthContext';
import { Button } from '@/components/shadcn/button';
//import newBaseSway from '../media/transparent-base-sway.webm';
import newBaseSway from '../media/transparent-base-sway.gif';
import talkingSprite from '../media/talking-sprite.gif';
import celebration from '../media/celebration-unscreen.gif';
import correctSound from '../media/correct-answer-sound.mp3';
import correctAudio from '../media/correct-audio.mp3';
import incorrectAudio from '../media/incorrect-audio.mp3';

import AddyDancing from '../media/Addy-Dancing.gif';
import AddyBaseSway from '../media/Addy-Base-Sway.gif';
import AddyTalking from '../media/Addy-Talking.gif';
import AddyAskingQuestion from '../media/Addy-Asking-Question.gif';
import { Card, CardHeader, CardTitle, CardDescription } from '@/components/shadcn/card';
import ProgressBar from './ProgressBar/ProgressBar';  // Import the ProgressBar component
import RecordRTC from 'recordrtc';
import './HomePageV2.css';
import ErrorBoundary from './ErrorBoundary';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the styles

function HomePageV2({ onQuizStart, preventNavigation, onStateChange, onInputChange }) {
  // const { lessonId } = useParams(); dont need this
  const [latestLesson, setLatestLesson] = useState(null);
  const [showAnimation, setShowAnimation] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [aiResponse, setAiResponse] = useState('');
  const [sessionStarted, setSessionStarted] = useState(false);
  const [isTalking, setIsTalking] = useState(false);
  const [isAudioInputEnabled, setIsAudioInputEnabled] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [currentLessonStep, setCurrentLessonStep] = useState(null);
  const [audioError, setAudioError] = useState(null);

  const [basiclessonText, setBasicLessonText] = useState('');
  const [whichVideo, setWhichVideo] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [selectAnswerMessage, setSelectAnswerMessage] = useState(true);
  const [answerSelected, setAnswerSelected] = useState(false);
  const [explanationText, setExplanationText] = useState('');
  const [showButtons, setShowButtons] = useState(true);
  const [isInitialStep, setIsInitialStep] = useState(true);
  const [showCelebration, setShowCelebration] = useState(false);

  const [addyisQuestioning, setAddyisQuestioning] = useState(false);

  const audioElementRef = useRef(null);
  const audioContextRef = useRef(null);
  const audioQueue = useRef([]);
  const isPlayingAudio = useRef(false);
  const [needsUserInteraction, setNeedsUserInteraction] = useState(false);
  const [hasUserInteracted, setHasUserInteracted] = useState(false);

  const audioRef = useRef(null);
  const explanationAudioRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const eventSourceRef = useRef(null);

  const [aiMessageCount, setAiMessageCount] = useState(0);
  const [isTuitionMode, setIsTuitionMode] = useState(false);
  const [isChildSpeaking, setIsChildSpeaking] = useState(false);
  const [clarifierResolved, setClarifierResolved] = useState(false);

  const { setIsAuthenticated } = useContext(AuthContext);
  const { user, logout, loggedInChild } = useAuth();
  const navigate = useNavigate();

  const [childTopic, setChildTopic] = useState(null);
  const [isLessonComplete, setIsLessonComplete] = useState(false);

  const [aiThinking, setAiThinking] = useState(false);
  const [showCharacter, setShowCharacter] = useState(false);

  const [isCurrentStepQuestion, setIsCurrentStepQuestion] = useState(false);

  const [recorder, setRecorder] = useState(null);

  // Add this new state
  const [currentQuizQuestion, setCurrentQuizQuestion] = useState(null);
  const [sessionExpired, setSessionExpired] = useState(false);

  // Add this function near the top of your component
  const getDisplayType = (type) => {
    if (['Question','Multiple-Choice-Question', 'Free-Text-Question', 'Blank-Box-Question', 'Drag-And-Drop-Question'].includes(type)) {
      return 'Question';
    }
    return type;
  };

  useEffect(() => {
    fetchLessonAndChildTopic();
  }, [/*lessonId*/]); // dont need lessonId


    // Add effect to notify parent of state changes
    // remove the params and dependencies that i don't need at some point
    useEffect(() => {
      if (onStateChange) {
        onStateChange({
          latestLesson,
          currentLessonStep,
          totalSteps: latestLesson?.steps?.length,
          isQuizMode: false,
          lessonTitle: latestLesson?.title,
          currentView: 'lesson',
          sessionStarted,
          basiclessonText,
          isLessonComplete
        });
      }
    }, [
      currentLessonStep,
      latestLesson,
      sessionStarted,
      basiclessonText,
      isLessonComplete,
      onStateChange
    ]); 
  

  const fetchLessonAndChildTopic = async () => {
    try {
      setLoading(true);
      if (loggedInChild) {
        // First, fetch the child's current topic
        const childData = await get(`/children/${loggedInChild._id}`, true);
        if (childData.currentTopicId) {
          // Then fetch the childTopic using the currentTopicId
          const childTopic = await get(`/childTopics/${loggedInChild._id}/${childData.currentTopicId}`, true);
          setChildTopic(childTopic);
          setCurrentLessonStep(childTopic.currentLessonStep);
          
          // Set latestLesson using the lesson from childTopic
          if (childTopic.currentLesson) {
            setLatestLesson(childTopic.currentLesson);
          } else {
            throw new Error("No current lesson found for this child topic");
          }
        } else {
          throw new Error("No current topic found for this child");
        }
      } else {
        throw new Error("No logged in child found");
      }
      setLoading(false);
    } catch (err) {
      console.error('Error fetching lesson and child topic:', err);
      setError(`Failed to fetch the lesson: ${err.message}`);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }
  }, [/*lessonId*/]); // dont need lessonId

  useEffect(() => {
    // Add event listeners to both audio elements
    if (audioRef.current) {
      audioRef.current.addEventListener('play', handleAudioPlay);
      audioRef.current.addEventListener('pause', handleAudioPause);
      audioRef.current.addEventListener('ended', handleAudioEnded);
    }

    if (explanationAudioRef.current) {
      explanationAudioRef.current.addEventListener('play', handleAudioPlay);
      explanationAudioRef.current.addEventListener('pause', handleAudioPause);
      explanationAudioRef.current.addEventListener('ended', handleAudioEnded);
    }

    // Cleanup function
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('play', handleAudioPlay);
        audioRef.current.removeEventListener('pause', handleAudioPause);
        audioRef.current.removeEventListener('ended', handleAudioEnded);
      }
      if (explanationAudioRef.current) {
        explanationAudioRef.current.removeEventListener('play', handleAudioPlay);
        explanationAudioRef.current.removeEventListener('pause', handleAudioPause);
        explanationAudioRef.current.removeEventListener('ended', handleAudioEnded);
      }
    };
  }, []);

  const handleAudioPlay = () => {
    setIsTalking(true);
    //console.log('Audio started playing, isTalking set to true');
  };

  const handleAudioPause = () => {
    setIsTalking(false);
   // console.log('Audio paused, isTalking set to false');
  };

  const handleAudioEnded = () => {
    setIsTalking(false);
    setIsAudioPlaying(false);
   // console.log('Audio playback ended, isTalking set to false');
  };

  
  const updateAudioAndAnimation = (step, stepIndex) => {
    //console.log('Updating audio and animation for step:', stepIndex);
    setWhichVideo(stepIndex);
    setIsCurrentStepQuestion(getDisplayType(step.Type) === 'Question');
    
    if (getDisplayType(step.Type) !== 'Question') {
      // For non-question steps, set answerSelected to true to allow animation to play
      setAnswerSelected(true);
      setAddyisQuestioning(false);
    } else {
      // Reset for question steps
      setAnswerSelected(false);
      setAddyisQuestioning(true);
    }

    // Play audio for non-question steps
  //  if (step.audio) {
  //   playAudio(step.audio);
  //  }
  }; 

  useEffect(() => {
    if (sessionStarted && latestLesson && latestLesson.steps[currentLessonStep]) {
      const currentStep = latestLesson.steps[currentLessonStep];
      updateAudioAndAnimation(currentStep, currentLessonStep);
      
      // Update the current quiz question if it's a question step
      if (getDisplayType(currentStep.Type) === 'Question') {
        setCurrentQuizQuestion(currentStep.quizQuestionId);
      } else {
        setCurrentQuizQuestion(null);
      }

      // Small delay to ensure audio is loaded
      if (!isInitialStep) {
      setTimeout(() => {
        playAudio(currentStep.audio);
      }, 100); 
    }

    }
  }, [currentLessonStep, sessionStarted, latestLesson]);

  const playAudio = (audioSource) => {
    if (audioElementRef.current) {
      initializeAudioContext();
      if (audioSource) {
        audioElementRef.current.src = audioSource;
        audioElementRef.current.load();
      }
      const playPromise = audioElementRef.current.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            //console.log('Audio started playing');
            setIsTalking(true);
            setShowButtons(false); // Hide buttons while audio is playing
          })
          .catch(e => {
            console.error("Error playing audio:", e);
            setNeedsUserInteraction(true);
          });
      }

      // Add an 'ended' event listener to show buttons when audio finishes
      audioElementRef.current.onended = () => {
        //console.log('Audio playback ended');
        setIsTalking(false);
        setShowButtons(true); // Show buttons after audio finishes
      };
    }
  };

  const handleStartLearning = async () => {
    if (latestLesson) {
      try {
        const { sessionId } = await get('/ai-tutor/generate', true);
        setBasicLessonText(latestLesson.steps[childTopic.currentLessonStep].TalkingPoint);
        setCurrentLessonStep(childTopic.currentLessonStep);
        setLatestLesson(prevLesson => ({ ...prevLesson, sessionId }));
        setSessionStarted(true);
        setShowCharacter(true);
        
        // Play the audio for the first step
        if (latestLesson.steps[childTopic.currentLessonStep].audio) {
          playAudio(latestLesson.steps[childTopic.currentLessonStep].audio);
          setHasUserInteracted(true);
        }

        setIsInitialStep(false);
        
        setAnswerSelected(false);
        setAddyisQuestioning(false);
        setSelectAnswerMessage(true);
        setShowButtons(true);
        setExplanationText('');

        const message = {
          'role': 'assistant',
          'content': latestLesson.steps[currentLessonStep].TalkingPoint
        };

        await post('/ai-tutor/continue-lesson', { sessionId: sessionId, message: message }, true);

        setIsAudioInputEnabled(true);
        setShowAnimation(true);
      } catch (err) {
        console.error('Error starting AI tutor session:', err);
      }
    } else {
      //console.log("No lesson available");
    }
  };

  const handleContinueLearning = async () => {
    const nextStep = currentLessonStep + 1;
    if (latestLesson && nextStep < latestLesson.steps.length) {
      const message = {
        'role': 'assistant',
        'content': latestLesson.steps[nextStep].TalkingPoint
      };
      setAiResponse('');

      await post('/ai-tutor/continue-lesson', { sessionId: latestLesson.sessionId, message: message }, true);

      setBasicLessonText(latestLesson.steps[nextStep].TalkingPoint);
      setCurrentLessonStep(nextStep);
      setAnswerSelected(false);
      setAddyisQuestioning(false);
      setSelectAnswerMessage(true);
      setClarifierResolved(false);
      setExplanationText('');
      setShowButtons(true);
      setIsTuitionMode(false);
      // Update the current lesson step in the database
      if (loggedInChild && childTopic) {
        try {
          await put(`/childTopics/${loggedInChild._id}/${childTopic.topicId}/lessonStep`, { currentLessonStep: nextStep }, true);
        } catch (error) {
          console.error('Failed to update lesson step in database:', error);
        }
      }

      // The audio will be played by the useEffect hook
    } else {
      //console.log("Lesson completed!");
      setIsLessonComplete(true);
    }
  };

  const handleGoBack = async () => {
    const previousStep = currentLessonStep - 1;
    if (previousStep >= 0) {
      const message = {
        'role': 'assistant',
        'content': latestLesson.steps[previousStep].TalkingPoint
      };
      setAiResponse('');

      await post('/ai-tutor/continue-lesson', { sessionId: latestLesson.sessionId, message: message }, true);

      setBasicLessonText(latestLesson.steps[previousStep].TalkingPoint);
      setCurrentLessonStep(previousStep);
      setAnswerSelected(false);
      setAddyisQuestioning(false);
      setSelectAnswerMessage(true);
      setClarifierResolved(false);
      setExplanationText('');
      setShowButtons(true);
      // Update the current lesson step in the database
      if (loggedInChild && childTopic) {
        try {
          await put(`/childTopics/${loggedInChild._id}/${childTopic.topicId}/lessonStep`, { currentLessonStep: previousStep }, true);
        } catch (error) {
          console.error('Failed to update lesson step in database:', error);
        }
      }
    } else {
      //console.log("Already at the first step of the lesson");
    }
    setIsLessonComplete(false);
  };

  const handleDontUnderstand = async () => {
    if (!latestLesson || !latestLesson.sessionId) {
      console.error('No active session found');
      return;
    }

    try {
      setAiThinking(true);  // Set AI thinking to true
      await post('/ai-tutor/dont-understand', { sessionId: latestLesson.sessionId }, true);
      //console.log('Don\'t understand request sent successfully');
      setIsTuitionMode(true);
      setAiMessageCount(0);  // Reset the counter when entering tuition mode
      setClarifierResolved(false);
      startListeningToStream(latestLesson.sessionId);
    } catch (err) {
      console.error('Error sending don\'t understand request:', err);
      setAiThinking(false);  // Set AI thinking to false if there's an error
    }
  };

  const handleChildSpeakButton = () => {
    if (isChildSpeaking) {
      stopRecording();
      setIsChildSpeaking(false);
      setAiThinking(true);  // Set AI thinking to true when child finishes speaking
    } else {
      startRecording();
      setIsChildSpeaking(true);
    }
  };

  const handleUnderstandNow = async () => {
    if (!latestLesson || !latestLesson.sessionId) {
      console.error('No active session found');
      return;
    }

    try {
      await post('/ai-tutor/understand-now', { sessionId: latestLesson.sessionId }, true);
      //console.log('Understand now request sent successfully');
      setIsTuitionMode(false);
      setClarifierResolved(true);
      startListeningToStream(latestLesson.sessionId);
    } catch (err) {
      console.error('Error sending understand now request:', err);
    }
  };

  const handleAnswerSelect = (isCorrect, selectedAnswer) => {
    //console.log('Answer selected:', { isCorrect, selectedAnswer });

    setAddyisQuestioning(false);
    
    
    if (latestLesson && latestLesson.steps[currentLessonStep]) {
      const step = latestLesson.steps[currentLessonStep];
      setSelectAnswerMessage(false);
      setShowButtons(false);
      
      const playFeedbackAudio = () => {
        // Play correct or incorrect audio
        setShowCelebration(false);
        const audioToPlay = isCorrect ? correctAudio : incorrectAudio;
        playAudio(audioToPlay);
        
        if (audioElementRef.current) {
          audioElementRef.current.onended = () => {
            setCurrentQuizQuestion(null);
            setAnswerSelected(true);          
            setShowButtons(true);
            if (step.AnswerExplanation) {
              setExplanationText(step.AnswerExplanation);
              
              // Handle the delay between animation and explanation audio start
              if (step.animationDelay >= 0) {
                playAudio(step.AnswerExplanationAudio);
                setTimeout(() => {
                  setWhichVideo(currentLessonStep);
                }, step.animationDelay * 1000);
              } else {
                setWhichVideo(currentLessonStep);
                setTimeout(() => {
                  playAudio(step.AnswerExplanationAudio);
                }, Math.abs(step.animationDelay) * 1000);
              }
            } else {
              // If there's no explanation, don't show animation or play audio
              setWhichVideo(null);
              setExplanationText('');
            }
          };
        }
      };
      
      if (isCorrect) {
        // First play correctSound if the answer is correct
        playAudio(correctSound);
        setShowCelebration(true);
        
        // Set up the onended event for correctSound to play the feedback audio
        if (audioElementRef.current) {
          audioElementRef.current.onended = playFeedbackAudio;
        }
      } else {
        // If the answer is incorrect, directly play the feedback audio
        playFeedbackAudio();
      }
    }
  };

  const handleAudioError = (event) => {
    console.error('Audio error event:', event);
    setAudioError(`Failed to load audio: ${event.target.error?.message || 'Unknown error'}`);
    setIsTalking(false);
    setIsAudioPlaying(false);
  };

  const enableAudioInput = () => {
    setIsAudioInputEnabled(true);
  };

  const startRecording = () => {
    if (!isAudioInputEnabled) return;

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        const newRecorder = RecordRTC(stream, {
          type: 'audio',
          // mimeType is not specified, letting RecordRTC choose
          recorderType: RecordRTC.StereoAudioRecorder,
          numberOfAudioChannels: 1
        });
        
        newRecorder.startRecording();
        setRecorder(newRecorder);
        setIsRecording(true);
      })
      .catch(err => console.error('Error accessing microphone:', err));
  };

  const stopRecording = () => {
    if (!recorder) return;

    recorder.stopRecording(() => {
      const blob = recorder.getBlob();
      sendAudioQuestion(blob);
      setIsRecording(false);
      setRecorder(null);
    });
  };

  const sendAudioQuestion = async (audioBlob) => {
    if (!latestLesson || !latestLesson.sessionId) {
      console.error('No active session found');
      return;
    }

    const formData = new FormData();
    formData.append('audio', audioBlob, 'question.webm');
    formData.append('sessionId', latestLesson.sessionId);

    try {
      await post('/ai-tutor/audio-question', formData, true);
      //console.log('Audio question sent successfully');
      startListeningToStream(latestLesson.sessionId);
    } catch (err) {
      console.error('Error sending audio question:', err);
    }
  };

  const initializeAudioContext = () => {
    if (!audioContextRef.current) {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    }
    if (audioContextRef.current.state === 'suspended') {
      audioContextRef.current.resume();
    }
  };

  const playNextAudio = async () => {
    if (audioQueue.current.length === 0) {
      isPlayingAudio.current = false;
      setIsTalking(false);
      return;
    }
  
    isPlayingAudio.current = true;
    const base64Audio = audioQueue.current.shift();
  
    try {
      if (audioElementRef.current) {
        // Set the src directly to the data URL
        audioElementRef.current.src = `data:audio/mpeg;base64,${base64Audio}`;
        audioElementRef.current.onplay = () => setIsTalking(true);
        audioElementRef.current.onended = () => {
          setIsTalking(false);
          playNextAudio();
        };
        audioElementRef.current.onerror = (error) => {
          console.error('Error playing audio:', error);
          playNextAudio();
        };
  
        try {
          await audioElementRef.current.play();
        } catch (error) {
          console.error('Autoplay prevented:', error);
          setNeedsUserInteraction(true);
        }
      }
    } catch (error) {
      console.error('Error playing audio:', error);
      playNextAudio();
    }
  };

  // need this function to make audio autoplay work on ios
  // ios requires user interaction before autoplaying
  const handlePlayAudio = () => {
    if (audioElementRef.current) {
      audioElementRef.current.play()
        .then(() => setNeedsUserInteraction(false))
        .catch(error => console.error('Error playing audio:', error));
    }
    setHasUserInteracted(true);
  };
  


  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  };

  const startListeningToStream = (sessionId) => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    eventSourceRef.current = new EventSource(`${process.env.REACT_APP_API_URL}/ai-tutor/stream?sessionId=${sessionId}`);

    let currentAiResponse = '';
    setAiThinking(true);  // Show "AI is thinking" message when stream starts

    eventSourceRef.current.onmessage = function (event) {
      if (event.data === 'TALKING_START') {
        setAiResponse('');
        currentAiResponse = '';
        setShowButtons(false);  // Reset button visibility
      } else if (event.data.startsWith('AUDIO_DATA::')) {
        setAiMessageCount(prevCount => prevCount + 1);
        //console.log('this is the message count', aiMessageCount);
        
        const base64Audio = event.data.split('::')[1];
        audioQueue.current.push(base64Audio);
        if (!isPlayingAudio.current) {
          playNextAudio();
          // Set the AI response when audio starts playing
          setAiThinking(false);  // Hide "AI is thinking" message
          setAiResponse(currentAiResponse);
                  // Check if the message includes the "continue learning" phrase
        if (currentAiResponse.includes("Good job! Now let's continue learning!") || 
        (isTuitionMode && aiMessageCount >= 20)) {
          setShowButtons(true);  // Show buttons after this message
        }
        }
      } else if (event.data === 'TALKING_END') {
        // Don't set isTalking to false here, as audio might still be playing
      } else if (event.data === 'STREAM_END') {
        // Handle end of stream
        setAiThinking(false);  // Ensure "AI is thinking" message is hidden at stream end
      } else {
        setBasicLessonText('');
        currentAiResponse += event.data;
        
        // Check conditions to turn off tuition mode
        if (currentAiResponse.includes("Good job! Now let's continue learning!") || 
            (isTuitionMode && aiMessageCount >= 20)) {
          setIsTuitionMode(false);
          setClarifierResolved(true);
          setAiMessageCount(0);  // Reset the counter
        }
      }
    };

    eventSourceRef.current.onerror = function (error) {
      console.error('Error in EventSource:', error);
      eventSourceRef.current.close();
      setAiThinking(false);  // Ensure "AI is thinking" message is hidden on error
    };
  };

  const handleStartQuiz = async () => {
    if (!latestLesson || !childTopic || !loggedInChild) {
      console.error('Missing required data to start quiz');
      return;
    }

    if (preventNavigation) {
      
      onQuizStart(latestLesson, childTopic, user, loggedInChild);
    }
    else {

    try {
      // Set learning stage to "quiz"
      await put(`/childTopics/${loggedInChild._id}/${childTopic.topicId}/learningStage`, { learningStage: 'quiz' }, true);

      // Create quiz submission with 20 random questions
      const response = await post('/quiz/quiz-submissions', {
        lessonId: latestLesson._id,
        userId: user._id,
        childId: loggedInChild._id,
        topicId: childTopic.topicId,
        QuizStatus: 'Pending'
      }, true);

      const quizSubmissionId = response._id;

      // Navigate to the quiz page
      navigate(`/quiz/${childTopic.topicId}/${latestLesson._id}`, { state: { quizSubmissionId } });
    } catch (error) {
      console.error('Error starting quiz:', error);
      // Handle error (e.g., show error message to user)
    }
  }

  };

  useEffect(() => {
    let intervalId;
    if (sessionStarted && latestLesson?.sessionId) {
      const checkSessionStatus = async () => {
        try {
          const response = await get(`/ai-tutor/check-session/${latestLesson.sessionId}`);
          if (response.status === 'expired') {
            // Instead of showing alert, silently create new session
            try {
              const { sessionId } = await get('/ai-tutor/generate', true);
              setLatestLesson(prevLesson => ({ ...prevLesson, sessionId }));
              // Optionally log for debugging
              console.log('Session renewed automatically');
            } catch (error) {
              console.error('Error creating new session:', error);
            }
          }
        } catch (error) {
          // If check fails, try to create new session instead of showing error
          try {
            const { sessionId } = await get('/ai-tutor/generate', true);
            setLatestLesson(prevLesson => ({ ...prevLesson, sessionId }));
            // Optionally log for debugging
            console.log('New session created after check failure');
          } catch (renewError) {
            console.error('Error creating new session:', renewError);
          }
        }
      };

      intervalId = setInterval(checkSessionStatus, 1 * 60 * 1000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [sessionStarted, latestLesson?.sessionId]);

  if (loading) {
    return <div className="flex items-center justify-center min-h-screen">Loading...</div>;
  }

  if (error) {
    return <div className="flex items-center justify-center min-h-screen text-red-500">{error}</div>;
  }

  const currentStep = latestLesson && latestLesson.steps[currentLessonStep];


  return (
    <ErrorBoundary>
    <div className="min-h-screen p-4 bg-slate-200">
      {/* Hidden audio element */}
      <audio ref={audioElementRef} style={{ display: 'none' }} />

      <div className="w-full max-w-none" style={{ opacity: 1.0 }}>
        {!sessionStarted ? (
          // Initial view with centered content
          <div className="flex flex-col items-center justify-center min-h-screen">
            <h1 className="text-3xl font-semibold text-foreground mb-8 text-center px-4">Welcome to Your Lesson!</h1>
            <Button
              onClick={handleStartLearning}
              className="bg-indigo-600 hover:bg-indigo-700 text-white py-3 px-6 text-xl transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {currentLessonStep === 0 ? "Start Learning!" : "Continue Learning"}
            </Button>
          </div>
        ) : (
          // Lesson content view
          <div className="flex flex-col">
            {/*latestLesson && (
              <Card className="mb-4 w-full sm:w-[90%] md:w-[80%] lg:w-[70%] border-black border-2 mx-auto bg-slate-300">
                <CardHeader className="">
                  <CardTitle className="text-xl sm:text-2xl">{latestLesson.title}</CardTitle>
                  <CardDescription className="text-base sm:text-lg">{latestLesson.description}</CardDescription>
                </CardHeader>
              </Card>
            )*/}
            
            {isLessonComplete ? (
              <div className="flex flex-col items-center justify-center px-4">
                <p className="text-xl sm:text-2xl font-bold mb-4 text-center">
                  You've finished the lesson! Are you ready to do some practice questions?
                </p>
                <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
                  <Button
                    onClick={handleStartQuiz}
                    className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Start Practice Questions
                  </Button>
                  <Button
                    onClick={handleGoBack}
                    className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                  >
                    Go Back
                  </Button>
                </div>
              </div>
            ) : (
              // Existing lesson content
              <div className="flex flex-col">
                {/* Container for SingleQuestionPage, ReactPlayer, and ProgressBar */}
                <div className="relative w-full sm:w-[90%] md:w-[90%] lg:w-[80%] bg-white mx-auto mb-2 border-2 border-black rounded-lg shadow-lg shadow-2xl" style={{ height: '75vh' /*`${isCurrentStepQuestion ? '60vh' : '50vh'}`*/ }}>
                  {latestLesson && currentLessonStep >= 0 && (
                    <div className="absolute inset-0 z-0" style={{ overflow: 'hidden' }}>
                      {latestLesson.steps[currentLessonStep].animation && (
                        <ReactPlayer
                          url={latestLesson.steps[currentLessonStep].animation}
                          playing={whichVideo === currentLessonStep && (!isCurrentStepQuestion || answerSelected)}
                          playbackRate={1 / (latestLesson.steps[currentLessonStep].slowDownMultiplier || 1)}
                          muted={true}
                          controls={true}
                          config={{
                            file: {
                              attributes: {
                                controlsList: "nofullscreen nodownload noplaybackrate",
                              },
                            },
                          }}
                          width="100%"
                          height="100%"
                          playsInline={true}
                          onError={(e) => console.error('Error playing video:', e)}
                          onReady={() => console.log('Video is ready to play')}
                          onStart={() => console.log('Video started playing')}
                          onPause={() => console.log('Video paused')}
                          onEnded={() => console.log('Video ended')}
                        />
                      )}
                    </div>
                  )}
                  {isCurrentStepQuestion && (!answerSelected || !currentStep.AnswerExplanation) && (
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="bg-white rounded-lg shadow-lg p-4 w-full h-full overflow-y-auto flex items-center justify-center">
                        {currentQuizQuestion && (
                          <SingleQuestionPage
                            key={`question-${currentLessonStep}`}
                            quizQuestion={currentQuizQuestion}
                            onAnswerSelect={handleAnswerSelect}
                            ButtonsAreClickable={!answerSelected}
                            onInputChange={onInputChange}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {/* Modified ProgressBar and Character container */}
                <div className="w-full sm:w-[90%] md:w-[80%] lg:w-[70%] mx-auto mt-4 relative">
                  <div className="flex flex-col items-center justify-center">
                    {latestLesson && (
                      <div className="w-full max-w-[300px]"> {/* Constrain width here */}
                        <ProgressBar 
                          currentQuestion={currentLessonStep + 1} 
                          totalQuestions={latestLesson.steps.length} 
                          labelText="Step"
                        />
                      </div>
                    )}
                    
                    {/* Character container */}
                    {showCharacter && (
                      <div className="character-container absolute right-0 bottom-0">
                        <img
                          src={AddyDancing}
                          alt="Addy the Tutor celebrating"
                          className="w-full h-full object-cover"
                          style={{ 
                            display: showCelebration ? 'block' : 'none'//,
                            //width: '100%',
                            //height: 'auto',
                          }}
                        />
                        <img
                          src={AddyBaseSway}
                          alt="Addy the Tutor"
                          className="w-full h-full object-cover"
                          style={{ 
                            display: !isTalking && !showCelebration ? 'block' : 'none'
                          }}
                        />
                        <img
                          src={AddyTalking}
                          alt="Addy the Tutor speaking"
                          className="w-full h-full object-cover"
                          style={{ 
                            display: isTalking && !showCelebration && !(isCurrentStepQuestion && addyisQuestioning) ? 'block' : 'none'
                          }}
                        />
                        <img
                          src={AddyAskingQuestion}
                          alt="Addy the Tutor asking a question"
                          className="w-full h-full object-cover"
                          style={{ 
                            display: isTalking && !showCelebration && (isCurrentStepQuestion && addyisQuestioning) ? 'block' : 'none'
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>

                {/* Audio players */}
                <div className="mb-2 w-full sm:w-[90%] md:w-[80%] lg:w-[57%] mx-auto">
                  <audio
                    ref={audioRef}
                    src={currentStep && currentStep.audio}
                    onPlay={handleAudioPlay}
                    onPause={handleAudioPause}
                    onEnded={handleAudioEnded}
                    onError={handleAudioError}
                    className="w-full mb-2"
                    style={{ display: showAnimation ? 'block' : 'none' }}
                    playsinline
                    //controls
                  />
                  {/*answerSelected && currentStep && currentStep.AnswerExplanationAudio &&*/ (
                    <audio
                      ref={explanationAudioRef}
                      src={currentStep.AnswerExplanationAudio}
                      onPlay={handleAudioPlay}
                      onPause={handleAudioPause}
                      onEnded={handleAudioEnded}
                      onError={handleAudioError}
                      className="w-full"
                      playsinline
                    />
                  )}
                </div>

                {audioError && (
                  <div className="mt-1 p-2 bg-red-100 border border-red-400 text-red-700 rounded text-sm">
                    {audioError}
                  </div>
                )}

                {/* User Interaction Buttons */}
                <div className="mt-2 flex flex-wrap justify-center items-center gap-2">
                  {isAudioInputEnabled && latestLesson.sessionId && !isTalking && (
                    <>
                      {isTuitionMode ? (
                        <>
                          {!aiThinking && (
                            <>
                              <Button
                                onClick={handleChildSpeakButton}
                                className={`${isChildSpeaking ? 'bg-red-500' : 'bg-green-500'} text-white px-3 py-1 sm:px-4 sm:py-2 text-sm sm:text-base rounded mr-2`}
                              >
                                {isChildSpeaking ? 'Finish speaking' : 'Speak to Addy'}
                              </Button>
                              {!isChildSpeaking && (
                                <Button
                                  onClick={handleContinueLearning}
                                  className="bg-blue-500 text-white px-3 py-1 sm:px-4 sm:py-2 text-sm sm:text-base"
                                >
                                  Continue learning
                                </Button>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {currentStep && currentStep.Type === 'clarifier' && !clarifierResolved ? (
                            <>
                              <Button
                                onClick={handleGoBack}
                                className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                              >
                                Go Back
                              </Button>
                              <Button
                                onClick={handleContinueLearning}
                                className="bg-green-500 text-white px-3 py-1 sm:px-4 sm:py-2 text-sm sm:text-base mr-2"
                              >
                                Continue Learning
                              </Button>
                             { /*<Button
                                onClick={handleDontUnderstand}
                                className="bg-yellow-500 text-white px-3 py-1 sm:px-4 sm:py-2 text-sm sm:text-base"
                              >
                                I don't understand
                              </Button>
                              */}
                            </>
                          ) : (
                            <>
                              {isCurrentStepQuestion && selectAnswerMessage ? (
                                <p>Please select an answer to continue.</p>
                              ) : (
                                <>
                                  {currentLessonStep > 0 && showButtons && (
                                    <Button
                                      onClick={handleGoBack}
                                      className="bg-gray-500 text-white px-3 py-1 sm:px-4 sm:py-2 text-sm sm:text-base mr-2"
                                    >
                                      Go Back
                                    </Button>
                                  )}
                                  {showButtons && (
                                  <Button
                                    onClick={handleContinueLearning}
                                    className="bg-blue-500 text-white px-3 py-1 sm:px-4 sm:py-2 text-sm sm:text-base"
                                  >
                                      Continue learning
                                    </Button>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>

                {/* AI Tutor Response */}
                {(basiclessonText || aiResponse || explanationText || aiThinking) && (
                  <div className="w-full sm:w-[90%] md:w-[80%] lg:w-[70%] mx-auto p-4 bg-slate-300 shadow-lg border-2 border-black rounded-lg mt-4 mb-4">
                    <h3 className="text-base sm:text-lg font-semibold mb-2 text-center">Addy's Response:</h3>
                    {aiThinking ? (
                      <p className="text-base sm:text-lg text-gray-700 text-center">Addy is thinking...</p>
                    ) : (
                      <p className="text-base sm:text-lg text-gray-700 whitespace-pre-wrap text-center">
                        {explanationText || basiclessonText || aiResponse}
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {/* Add this near your other UI elements */}
      {needsUserInteraction && !hasUserInteracted && (
        <Button onClick={handlePlayAudio} className="bg-blue-500 text-white px-4 py-2 rounded">
          Play Audio
        </Button>
      )}
      </div>
    </ErrorBoundary>
  );
}

export default HomePageV2;
