import React from 'react';
import { Hand } from 'lucide-react';
import './RaiseHandBadge.css';
import { useHandRaisedQueue } from './useHandRaising';

export default function RaiseHandBadge({ id, isLocal }) {
  const handRaisedQueueNumber = useHandRaisedQueue(id);

  return (
    <div className={`has-hand-raised ${isLocal ? 'local' : 'remote'}`}>
      <Hand size={16} />
      <span className="queue-number">{handRaisedQueueNumber}</span>
    </div>
  );
}