import './Tile.css';
import { DailyVideo, useVideoTrack } from '@daily-co/daily-react';
import Username from '../Username/Username';
import { Ear, EarOff } from 'lucide-react';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VoiceOverOffIcon from '@mui/icons-material/VoiceOverOff';

import { useHandRaising } from '../HandRaising/useHandRaising';
import RaiseHandBadge from '../HandRaising/RaiseHandBadge';

export default function Tile({ id, isScreenShare, TutorWatchMode, StudentWatchMode, isLocal, isAlone, canHearTutor, onHearingToggle, isMicMuted, onMicToggle, isLargeTile, lessonView, tutorEmails}) {
  const videoState = useVideoTrack(id);

  const { handRaisedParticipants } = useHandRaising(tutorEmails);
  const userHasHandRaised = handRaisedParticipants?.includes(id);

  let containerCssClasses = isLargeTile ? 'large-tile' :
    (TutorWatchMode ? 'tutor-watch-video' : 
    (StudentWatchMode ? 'student-watch-video' : 'tile-video'));

  if (isLocal && !isLargeTile) {
    containerCssClasses += ' self-view';
    if (isAlone) {
      containerCssClasses += ' alone';
    }
  }

  /* If a participant's video is muted, hide their video and
  add a different background color to their tile. */
  if (videoState.isOff) {
    containerCssClasses += ' no-video';
  }

  return (
    <div className={containerCssClasses}>
      <DailyVideo automirror sessionId={id} fit="contain" type={isScreenShare ? 'screenVideo' : 'video'} />
      {!isScreenShare && <Username id={id} isLocal={isLocal} StudentWatchModeUserName={StudentWatchMode} />}
      
      {/* Only show hand raise badge in tile if not in lesson view */}
      {userHasHandRaised && !lessonView && <RaiseHandBadge id={id} isLocal={isLocal} />}
      
      {/* Modified ear icon div */}
      { onHearingToggle && ( <div 
        className="absolute bottom-0 right-0 p-2 cursor-pointer" 
        onClick={onHearingToggle}
      >
        {canHearTutor ? <Ear color="#ffffff"/> : <EarOff color="#f63135"/>}
      </div> )}

      {/* Mic toggle */}
      {onMicToggle && (
        <div 
          className="absolute bottom-0 right-12 p-2 cursor-pointer" 
          onClick={onMicToggle}
        >
          {isMicMuted ? 
            <VoiceOverOffIcon sx={{ color: '#f63135' }} /> : 
            <RecordVoiceOverIcon sx={{ color: '#ffffff' }} />
          }
        </div>
      )}
    </div>
  );
}