import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { get } from '../utils/api';
import { Button } from './shadcn/button';

function UpcomingLessons({ createCall, startHairCheck }) {
  const [upcomingLessons, setUpcomingLessons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date());
  const { user } = useAuth();

  useEffect(() => {
    fetchUpcomingLessons();
  }, [user?._id]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 30000);

    return () => clearInterval(timer);
  }, []);

  const fetchUpcomingLessons = async () => {
    try {
      setLoading(true);
      const lessons = await get(`/lesson-schedules/upcoming/${user._id}`, true);
      //console.log('Upcoming Lessons:', lessons);
      setUpcomingLessons(lessons);
      setError(null);
    } catch (error) {
      console.error('Error fetching upcoming lessons:', error);
      setError('Failed to load upcoming lessons');
    } finally {
      setLoading(false);
    }
  };

  const canJoinLesson = (day, time) => {
    const currentDay = currentTime.toLocaleDateString('en-US', { weekday: 'long' });
    const currentTimeString = currentTime.toLocaleTimeString('en-GB', { 
      hour: '2-digit', 
      minute: '2-digit',
      hour12: false 
    });

    if (day !== currentDay) return false;

    const [lessonHour, lessonMinute] = time.split(':').map(Number);
    const lessonDate = new Date(currentTime);
    lessonDate.setHours(lessonHour, lessonMinute, 0, 0);

    const fiveMinutesBefore = new Date(lessonDate.getTime() - 5 * 60000);
    const thirtyFiveMinutesAfter = new Date(lessonDate.getTime() + 35 * 60000);

    return currentTime >= fiveMinutesBefore && currentTime <= thirtyFiveMinutesAfter;
  };

  const handleJoinLesson = (childId) => {
    createCall(childId).then((url) => {
      startHairCheck(url);
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  // Check if user has access
  if (user && user.accessStatus !== 'has_access') {
    return (
      <div className="max-w-4xl mx-auto p-6">
        <div className="bg-amber-50 border border-amber-200 rounded-lg p-6 text-center">
          <h2 className="text-2xl font-bold mb-4 text-amber-800">Subscription Required</h2>
          <p className="text-amber-700 text-lg">
            Please pay for your monthly subscription in order to join the classroom.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-4">
      <h2 className="text-2xl font-bold mb-6">Upcoming Lessons</h2>
      
      {upcomingLessons.map((lesson, index) => (
        <div 
          key={`${lesson.childId}-${lesson.day}-${lesson.time}`}
          className="border rounded-lg p-4 shadow-sm bg-white"
        >
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-lg font-semibold">{lesson.childName}</h3>
              <p className="text-gray-600">
                {lesson.day} at {lesson.time}
              </p>
            </div>
            <div className="text-right">
              {canJoinLesson(lesson.day, lesson.time) ? (
                <Button 
                  onClick={() => handleJoinLesson(lesson.childId)}
                  className="bg-indigo-600 hover:bg-indigo-700"
                >
                  Join Lesson
                </Button>
              ) : (
                <>
                  <p className="text-sm text-gray-500 mb-2">
                    You'll be able to join the lesson 5 minutes before it begins
                  </p>
                  <Button 
                    disabled
                    className="bg-gray-500"
                  >
                    Join Lesson
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      ))}

      {upcomingLessons.length === 0 && (
        <p className="text-gray-500 text-center">No upcoming lessons scheduled</p>
      )}
    </div>
  );
}

export default UpcomingLessons;