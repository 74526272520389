import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate, useLocation } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import AllLessonsFrontend from './components/AllLessonsFrontend';
import HomePageV2 from './components/HomePageV2';
import ChildrenDashboard from './components/ChildrenDashboard';
import QuizPage from './views/Quiz/Quiz';
import ChildTestResults from './components/ChildTestResults';

import LearningSession from './components/LearningSession';

import { AuthProvider, useAuth } from './contexts/AuthContext';
import Cookies from 'js-cookie';
import {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from '@/components/shadcn/navigation-menu';
import { Button } from '@/components/shadcn/button';
import { cn } from "@/lib/utils";
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';

import TutorTuitionCall from './components/DailyVideoCalls/TuitionCall/TutorTuitionCall';
import TuitionCall from './components/DailyVideoCalls/TuitionCall/TuitionCall';
import TuitionCallTest from './components/DailyVideoCalls/TuitionCall/TuitionCallTest';

//import SubscriptionPlans from './components/SubscriptionPlans';
import LandingPage from './components/LandingPage';

import Logo from './media/Transparent-Star-Only-Logo.png';
import OnboardingFlow from './components/Onboarding/OnboardingFlow';
import UpcomingLessons from './components/UpcomingLessons';
import { SubscriptionManagement } from './components/SubscriptionManagement';
import { Toaster } from './components/shadcn/toaster';
import TutorUpcomingLessons from './components/TutorUpcomingLessons';
import { Sheet, SheetContent, SheetTrigger } from "@/components/shadcn/sheet";
import { Menu } from "lucide-react";
import { Link } from 'react-router-dom';

// Add near the top of your file, after imports
const TEST_MODE = process.env.REACT_APP_TEST_MODE === 'true';

function PrivateRoute({ children }) {
  const { isAuthenticated, user } = useAuth();
  const token = Cookies.get('token');
  const location = useLocation();

  if (!isAuthenticated && !token) {
    return <Navigate to="/" />;
  }

  // Simpler redirect logic
  if (user?.accessStatus === 'inactive' && location.pathname !== '/onboarding') {
    return <Navigate to="/onboarding" />;
  }

  return children;
}

function OnboardingRoute({ children }) {
  const { user } = useAuth();
  
  // If user has an active subscription, redirect to children-dashboard
  if (user?.accessStatus !== 'inactive') {
    return <Navigate to="/upcoming-lessons" />;
  }

  // Otherwise, show the onboarding flow
  return children;
}

function TutorRoute({ children }) {
  const { isAuthenticated, user } = useAuth();
  const token = Cookies.get('token');
  const location = useLocation();

  if (!isAuthenticated && !token) {
    return <Navigate to="/login" />;
  }

  if (!user?.isTutor) {
    return <Navigate to="/upcoming-lessons" />;
  }

  return children;
}

function LoggedInNavigationMenuBar() {
  const { user, logout } = useAuth();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  // Function to check if Let's Learn should be shown
  const shouldShowLetsLearn = () => {
    const authorizedEmails = [
      'nife.ojo@hotmail.co.uk',
      'sakinboyewa@hotmail.co.uk',
      'tunde337@gmail.com'
    ];
    return TEST_MODE || authorizedEmails.includes(user?.email);
  };

  const handleLogout = () => {
    logout();
    // You might want to add navigation to the login page here
    // or handle it in your logout function
  };

  const isActive = (path) => {
    if (path === '/lets-learn') {
      // Consider '/', '/lesson/:lessonId', and '/quiz/:lessonId' as active for "Let's Learn!"
      return location.pathname === '/' || 
             location.pathname.startsWith('/lesson/') || 
             location.pathname.startsWith('/quiz/') ||
             location.pathname ==='/lets-learn';
    }
    return location.pathname === path;
  };

  return (
    <div className="w-full bg-slate-200 shadow-md">
      <div className="flex justify-between items-center px-4 py-2">
        {/* Mobile Menu (Sheet) */}
        <Sheet open={open} onOpenChange={setOpen}>
          <SheetTrigger asChild className="xl:hidden">
            <Button variant="ghost" size="icon">
              <Menu className="h-6 w-6" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </SheetTrigger>
          <SheetContent side="left" className="w-[240px] sm:w-[300px]">
            <nav className="flex flex-col gap-4">
              {user?.isTutor && (
                <Link 
                  to="/tutor-upcoming-lessons"
                  className={cn(
                    "px-4 py-2 text-sm font-medium rounded-md transition-colors",
                    isActive('/tutor-upcoming-lessons') 
                      ? "bg-black text-white" 
                      : "text-gray-700 hover:bg-gray-100"
                  )}
                  onClick={() => setOpen(false)}
                >
                  Tutor Upcoming Lessons
                </Link>
              )}
              <Link 
                to="/upcoming-lessons"
                className={cn(
                  "px-4 py-2 text-sm font-medium rounded-md transition-colors",
                  isActive('/upcoming-lessons') 
                    ? "bg-black text-white" 
                    : "text-gray-700 hover:bg-gray-100"
                )}
                onClick={() => setOpen(false)}
              >
                Upcoming Lessons
              </Link>
              <Link 
                to="/children-dashboard"
                className={cn(
                  "px-4 py-2 text-sm font-medium rounded-md transition-colors",
                  isActive('/children-dashboard') 
                    ? "bg-black text-white" 
                    : "text-gray-700 hover:bg-gray-100"
                )}
                onClick={() => setOpen(false)}
              >
                Children Dashboard
              </Link>
              {/* Conditionally render test mode routes */}
              {shouldShowLetsLearn() && (
                <Link 
                  to="/lets-learn"
                  className={cn(
                    "px-4 py-2 text-sm font-medium rounded-md transition-colors",
                    isActive('/lets-learn') 
                      ? "bg-black text-white" 
                      : "text-gray-700 hover:bg-gray-100"
                  )}
                  onClick={() => setOpen(false)}
                >
                  Let's Learn!
                </Link>
              )}
              {TEST_MODE && (
                <>
                  <Link 
                    to="/learning-session"
                    className={cn(
                      "px-4 py-2 text-sm font-medium rounded-md transition-colors",
                      isActive('/learning-session') 
                        ? "bg-black text-white" 
                        : "text-gray-700 hover:bg-gray-100"
                    )}
                    onClick={() => setOpen(false)}
                  >
                    Learning Session
                  </Link>
                  <Link 
                    to="/tuition-session"
                    className={cn(
                      "px-4 py-2 text-sm font-medium rounded-md transition-colors",
                      isActive('/tuition-session') 
                        ? "bg-black text-white" 
                        : "text-gray-700 hover:bg-gray-100"
                    )}
                    onClick={() => setOpen(false)}
                  >
                    Video Test
                  </Link>
                </>
              )}
              {/*<Link 
                to="/child-test-results"
                className={cn(
                  "px-4 py-2 text-sm font-medium rounded-md transition-colors",
                  isActive('/child-test-results') 
                    ? "bg-black text-white" 
                    : "text-gray-700 hover:bg-gray-100"
                )}
                onClick={() => setOpen(false)}
              >
                Child Learning Progress
              </Link>*/}
              <Link 
                to="/manage-subscription"
                className={cn(
                  "px-4 py-2 text-sm font-medium rounded-md transition-colors",
                  isActive('/manage-subscription') 
                    ? "bg-black text-white" 
                    : "text-gray-700 hover:bg-gray-100"
                )}
                onClick={() => setOpen(false)}
              >
                Manage Subscription
              </Link>
            </nav>
          </SheetContent>
        </Sheet>

        {/* Desktop Navigation */}
        <NavigationMenu className="hidden xl:flex mr-auto">
          <NavigationMenuList>
            {user?.isTutor && (
              <NavigationMenuItem>
                <NavigationMenuLink 
                  className={cn(
                    "px-4 py-2 text-sm font-medium rounded-md transition-colors duration-150 ease-in-out",
                    isActive('/tutor-upcoming-lessons') 
                      ? "bg-black text-white" 
                      : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  )} 
                  href="/tutor-upcoming-lessons"
                >
                  Tutor Upcoming Lessons
                </NavigationMenuLink>
              </NavigationMenuItem>
            )}

            <NavigationMenuItem>
              <NavigationMenuLink 
                className={cn(
                  "px-4 py-2 text-sm font-medium rounded-md transition-colors duration-150 ease-in-out",
                  isActive('/upcoming-lessons') 
                    ? "bg-black text-white" 
                    : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                )} 
                href="/upcoming-lessons"
              >
                Upcoming Lessons
              </NavigationMenuLink>
            </NavigationMenuItem>

            <NavigationMenuItem>
              <NavigationMenuLink 
                className={cn(
                  "px-4 py-2 text-sm font-medium rounded-md transition-colors duration-150 ease-in-out",
                  isActive('/children-dashboard') 
                    ? "bg-black text-white" 
                    : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                )} 
                href="/children-dashboard"
              >
                Children Dashboard
              </NavigationMenuLink>
            </NavigationMenuItem>

            {/* Conditionally render test mode routes */}
            {shouldShowLetsLearn() && (
              <NavigationMenuItem>
                <NavigationMenuLink 
                  className={cn(
                    "px-4 py-2 text-sm font-medium rounded-md transition-colors duration-150 ease-in-out",
                    isActive('/lets-learn') 
                      ? "bg-black text-white" 
                      : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  )} 
                  href="/lets-learn"
                >
                  Let's Learn!
                </NavigationMenuLink>
              </NavigationMenuItem>
            )}

            {TEST_MODE && (
              <>
                <NavigationMenuItem>
                  <NavigationMenuLink 
                    className={cn(
                      "px-4 py-2 text-sm font-medium rounded-md transition-colors duration-150 ease-in-out",
                      isActive('/learning-session') 
                        ? "bg-black text-white" 
                        : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    )} 
                    href="/learning-session"
                  >
                    Learning Session
                  </NavigationMenuLink>
                </NavigationMenuItem>

                <NavigationMenuItem>
                  <NavigationMenuLink 
                    className={cn(
                      "px-4 py-2 text-sm font-medium rounded-md transition-colors duration-150 ease-in-out",
                      isActive('/tuition-session') 
                        ? "bg-black text-white" 
                        : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                    )} 
                    href="/tuition-session"
                  >
                    Video Test
                  </NavigationMenuLink>
                </NavigationMenuItem>
              </>
            )}

            {/*<NavigationMenuItem>
              <NavigationMenuLink 
                className={cn(
                  "px-4 py-2 text-sm font-medium rounded-md transition-colors duration-150 ease-in-out",
                  isActive('/child-test-results') 
                    ? "bg-black text-white" 
                    : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                )} 
                href="/child-test-results"
              >
                Child Learning Progress
              </NavigationMenuLink>
            </NavigationMenuItem>*/}
            <NavigationMenuItem>
              <NavigationMenuLink 
                className={cn(
                  "px-4 py-2 text-sm font-medium rounded-md transition-colors duration-150 ease-in-out",
                  isActive('/manage-subscription') 
                    ? "bg-black text-white" 
                    : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                )} 
                href="/manage-subscription"
              >
                Manage Subscription
              </NavigationMenuLink>
            </NavigationMenuItem>

          </NavigationMenuList>
        </NavigationMenu>
        <Button 
          onClick={handleLogout}
          variant="outline"
          size="sm"
          className="bg-red-500 text-white hover:bg-red-700 transition-colors duration-150 ease-in-out ml-4"
        >
          Logout
        </Button>
      </div>
    </div>
  );
}


function LoggedOutNavigationMenuBar() {
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToSection = (sectionId) => {
    if (location.pathname === '/') {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      navigate('/?section=' + sectionId);
    }
  };

  // Check if we're on login or register pages
  const isAuthPage = location.pathname === '/login' || location.pathname === '/register';

  return (
    <div className="w-full bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-6 py-4">
        <div className="flex items-center justify-between">
          {/* Logo Section - Always visible */}
          <Link to="/" className="flex-shrink-0">
            <div className="h-12 w-12 transform transition-transform duration-200 hover:scale-110"> 
              <img src={Logo} alt="Nova Learning" className="h-full w-full object-contain"/>
            </div>
          </Link>

          {/* Navigation Links - Hidden on auth pages */}
          {!isAuthPage && (
            <nav className="hidden md:flex items-center space-x-8">
              <NavigationMenu>
                <NavigationMenuList className="flex space-x-6">
                  <NavigationMenuItem>
                    <NavigationMenuLink 
                      className={cn(navigationMenuTriggerStyle(), "text-base cursor-pointer")}
                      onClick={() => scrollToSection('how-it-works')}
                    >
                      How it works
                    </NavigationMenuLink>
                  </NavigationMenuItem>
                  <NavigationMenuItem>
                    <NavigationMenuLink 
                      className={cn(navigationMenuTriggerStyle(), "text-base cursor-pointer")}
                      onClick={() => scrollToSection('reviews')}
                    >
                      Reviews
                    </NavigationMenuLink>
                  </NavigationMenuItem>
                  <NavigationMenuItem>
                    <NavigationMenuLink 
                      className={cn(navigationMenuTriggerStyle(), "text-base cursor-pointer")}
                      onClick={() => scrollToSection('pricing')}
                    >
                      Pricing
                    </NavigationMenuLink>
                  </NavigationMenuItem>
                  <NavigationMenuItem>
                    <NavigationMenuLink 
                      className={cn(navigationMenuTriggerStyle(), "text-base cursor-pointer")}
                      onClick={() => scrollToSection('faqs')}
                    >
                      FAQs
                    </NavigationMenuLink>
                  </NavigationMenuItem>
                </NavigationMenuList>
              </NavigationMenu>
            </nav>
          )}

          {/* Auth Buttons - Always visible */}
          <div className="flex items-center space-x-4">
            <Button variant="ghost" onClick={() => navigate('/login')}>
              Log in
            </Button>
            <Button 
              className="bg-gradient-to-r from-amber-500 to-orange-500 text-white"
              onClick={() => navigate('/register')}
            >
              Start Free Trial
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function OnlyLogoutButton() {
  const { logout } = useAuth();
  const location = useLocation();

  const handleLogout = () => {
    logout();
    // You might want to add navigation to the login page here
    // or handle it in your logout function
  };

  const isActive = (path) => {
    if (path === '/lets-learn') {
      // Consider '/', '/lesson/:lessonId', and '/quiz/:lessonId' as active for "Let's Learn!"
      return location.pathname === '/' || 
             location.pathname.startsWith('/lesson/') || 
             location.pathname.startsWith('/quiz/');
    }
    return location.pathname === path;
  };

  return (
    <div className="w-full bg-slate-200 shadow-md">
      <div className="flex justify-end items-center px-4 py-2">
        <Button 
          onClick={handleLogout}
          variant="outline"
          size="sm"
          className="bg-red-500 text-white hover:bg-red-700 transition-colors duration-150 ease-in-out ml-4"
        >
          Logout
        </Button>
      </div>
    </div>
  );
}



function AppContent() {
  const { isAuthenticated, user } = useAuth();
  const token = Cookies.get('token');

  return (
    <Router>
      <div>
        {/* deal with all the relevant navigation menu logic here*/}
        {(isAuthenticated || token) && user?.accessStatus === 'inactive' && <OnlyLogoutButton />}
        {(isAuthenticated || token) && user?.accessStatus !== 'inactive' && <LoggedInNavigationMenuBar />}
        {!(isAuthenticated || token) && <LoggedOutNavigationMenuBar />}
        <Routes>
          <Route 
            path="/" 
            element={
              isAuthenticated || token ? (
                <Navigate to="/children-dashboard" />
              ) : (
                <LandingPage />
              )
            } 
          />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route 
            path="/lets-learn" 
            element={
              <PrivateRoute>
                <AllLessonsFrontend />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/lesson/:lessonId" 
            element={
              <PrivateRoute>
                <HomePageV2 />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/quiz/:topicId/:lessonId" 
            element={
              <PrivateRoute>
                <QuizPage />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/children-dashboard" 
            element={
              <PrivateRoute>
                <ChildrenDashboard />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/child-test-results" 
            element={
              <PrivateRoute>
                <ChildTestResults />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/tuition-session" 
            element={
              <PrivateRoute>
                <TuitionCallTest />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/learning-session" 
            element={
              <PrivateRoute>
                <LearningSession />
              </PrivateRoute>
            } 
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          {/*<Route 
            path="/subscription" 
            element={
              <PrivateRoute>
                <SubscriptionPlans />
              </PrivateRoute>
            } 
          />*/}
          <Route 
            path="/onboarding" 
            element={
              <PrivateRoute>
                <OnboardingRoute>
                  <OnboardingFlow />
                </OnboardingRoute>
              </PrivateRoute>
            } 
          />
          <Route 
            path="/upcoming-lessons" 
            element={
              <PrivateRoute>
                {/*<UpcomingLessons />*/}
                <TuitionCall />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/manage-subscription" 
            element={
              <PrivateRoute>
                <SubscriptionManagement />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/tutor-upcoming-lessons" 
            element={
              <PrivateRoute>
                <TutorRoute>
                  <TutorTuitionCall />
                </TutorRoute>
              </PrivateRoute>
            } 
          />
        </Routes>
      </div>
    </Router>
  );
}

function App() {
  return (
    <AuthProvider>
      <AppContent />
      <Toaster />
    </AuthProvider>
  );
}

export default App;
