import TutorHomePageV2 from './TutorHomePageV2';
import TutorSingleQuestionPage from './Quiz/TutorSingleQuestionPage/TutorSingleQuestionPage';
import { useEffect, useState } from 'react';
import Tile from '../DailyVideoCalls/Tile/Tile';
import { Ear, EarOff } from 'lucide-react';
import './TutorLearningSession.css';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import VoiceOverOffIcon from '@mui/icons-material/VoiceOverOff';
import { useHandRaising } from '../DailyVideoCalls/HandRaising/useHandRaising';
import RaiseHandBadge from '../DailyVideoCalls/HandRaising/RaiseHandBadge';

function TutorLearningSession({ 
    studentState, 
    student, 
    canHearTutor, 
    onHearingToggle,
    isMicMuted,
    onMicToggle,
    tutorEmails,
    studentInput
}) {
    const [currentView, setCurrentView] = useState(null);
    const [delayedQuizQuestion, setDelayedQuizQuestion] = useState(null);
    const { handRaisedParticipants } = useHandRaising(tutorEmails);
    const userHasHandRaised = handRaisedParticipants?.includes(student.session_id);
    
   /* useEffect(() => {
        console.log('Student State in TutorLearningSession:', studentState);
    }, [studentState]);*/

    useEffect(() => {
        setCurrentView(studentState?.currentView);
    }, [studentState?.currentView]);

    // Add new useEffect to handle delayed quiz question updates
    useEffect(() => {
        if (currentView === 'quiz' && studentState?.quizSubmission) {
            const currentQuestion = studentState.quizSubmission.questionAttempts
                ?.filter(attempt => attempt.QuestionStatus === "not attempted")
                ?.[0]
                ?.LessonQuestionId ?? null;
            
            // Add a delay when the question changes
            setTimeout(() => {
                setDelayedQuizQuestion(currentQuestion);
            }, 1000); // 1 second delay
        }
    }, [studentState?.quizSubmission, currentView]);

    return (
        <div className='tutor-session-tile'>
            {/* Add hand raise badge at the top */}
            {userHasHandRaised && (
                
                    <RaiseHandBadge id={student.session_id} isLocal={false} />
                
            )}

            {/* Content based on currentView */}
            {currentView === 'lesson' && (
                <TutorHomePageV2 
                    studentState={studentState}
                    studentInput={studentInput}
                />
            )}
            
            {currentView === 'quiz' && studentState?.quizSubmission && (
                <TutorSingleQuestionPage 
                    quizQuestion={delayedQuizQuestion}  // Use delayed question instead
                    ButtonsAreClickable={false}
                    onAnswerSelect={() => {}}
                    studentInput={studentInput}
                />
            )}

            {/* Tile component rendered once, outside of conditional blocks */}
            <div key={student.session_id} className="absolute bottom-0 right-0">
                <Tile 
                    key={student.session_id} 
                    id={student.session_id} 
                    StudentWatchMode
                    lessonView = { true }
                />
            </div>

            {/* Modified ear icon div */}
            <div 
                className="absolute bottom-0 left-0 p-2 cursor-pointer z-50" 
                onClick={onHearingToggle}
            >
                {canHearTutor ? <Ear /> : <EarOff color="#f63135"/>}
            </div>

            {/* Add mic control next to hearing control */}
            <div 
                className="absolute bottom-0 left-12 p-2 cursor-pointer z-50" 
                onClick={onMicToggle}
            >
                {isMicMuted ? 
                    <VoiceOverOffIcon sx={{ color: '#f63135' }} /> : 
                    <RecordVoiceOverIcon  />
                }
            </div>
        </div>
    );
}

export default TutorLearningSession;